import React, {useEffect, useRef, useState} from 'react'
import store from "./store"
import {
    checkData,
    checkData0,
    checkDataArray,
    checkDataBool,
    checkDataFunction,
    checkDataObject,
    checkDataString,
    connectClass,
    dispatch,
    getFullNum,
    isNotNull,
    isNotNullOrEmpty,
    requireDefault,
    square,
    ternaryArray,
    ternaryObject,
    numberPointFixed,
    numberToFixed,
    currentCoin,
    currentCoins,
    toFixedNum, getFullNum18, numberToFixed18
} from 'functions'
import {Navigate, Route, Routes} from 'react-router-dom'
import {EthereumClient, w3mConnectors, w3mProvider} from '@web3modal/ethereum'
import {configureChains, createConfig, useAccount, useChainId, WagmiConfig} from 'wagmi'
import {
    mainnet,
    base,
    linea,
    scroll,
    optimism,
    arbitrum,
    polygonZkEvm,
    arbitrumSepolia,
} from 'wagmi/chains'
import blast from "./otherChains/blast";
import bob from "./otherChains/bob";
import taiko from "./otherChains/taiko";
import bnb from "./otherChains/bnb";
import polygonZkevmCardona from "./otherChains/polygonZkevmCardona";
// import bobTestnet from "./otherChains/bobTestnet";
import bnbTestnet from "./otherChains/bnbTestnet";
import vizing from "./otherChains/vizing";
import vizingTestnet from "./otherChains/vizingTestnet";
import {prepareWriteContract, readContract, signMessage, writeContract, waitForTransaction} from '@wagmi/core'
import {message} from 'antd'
import axios from 'axios'
import Web3 from "web3";
import {formatEther, parseEther} from "viem";
import {divide as npDivide, minus as npMinus, plus as npPlus, times as npTimes,} from 'number-precision'
import {jsonRpcProvider} from "wagmi/providers/jsonRpc";
import Chart from "./Chart";
import {createWeb3Modal, defaultWagmiConfig, useWeb3Modal} from "@web3modal/wagmi/react";
import {QRCodeSVG} from 'qrcode.react';
import SwiperCore, {Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.min.css'
import SuperGif from 'libgif'

let lastBlock
const firstCoinsPage = 1
let coinsPage = firstCoinsPage,
    contestCapCoinsPage = firstCoinsPage,
    contestHoldersCoinsPage = firstCoinsPage,
    claimFeeCoinsPage = firstCoinsPage
let superGif
let listedPhaseId
let claimFeeCoins1 = []
let currentCoinLongSymbol1
const sessionDev = sessionStorage.getItem('dev'),
    masterChain = sessionDev ? vizingTestnet : vizing,
    {id: masterChainId} = masterChain,
    isMasterContractFunc = chainId => [vizingTestnet, vizing].map(v => v['id']).includes(Number(chainId)),
    contractDatas = {
        // 测试
        421614: 'ETH',
        2442: 'ETH',
        28516: 'ETH',
        97: 'BNB',
        // 正式
        1: 'ETH',
        56: 'BNB',
        28518: 'ETH',
        8453: 'ETH',
        59144: 'ETH',
        534352: 'ETH',
        42161: 'ETH',
        10: 'ETH',
        1101: 'ETH',
        81457: 'ETH',
        60808: 'ETH',
        167000: 'ETH',
    },
    setTransactionChainId = chainId => Object.keys(contractDatas).includes(checkDataString(chainId).toString()) ? chainId : masterChainId,
    memeLogo = logo => `${sessionDev
        ? 'https://test-s3.likwid.meme/token/logo'
        : 'https://s3.likwid.meme/token/logo'}/${logo}`,
    prdChains = [base, linea, scroll, arbitrum, optimism, polygonZkEvm, blast, bob, taiko, bnb, mainnet],
    memeSlaveContracts = sessionDev
        ? [
            arbitrumSepolia,
            polygonZkevmCardona,
            bnbTestnet
        ]
        : prdChains,
    memeContracts = [
        [masterChain.id, masterChain.name],
        ...memeSlaveContracts.map(v => [v.id, v.name])
    ],
    launchPrdOpen = true || sessionDev,
    swapPrdOpen = true || sessionDev,
    portfolioPrdOpen = true || sessionDev,
    votePrdOpen = true || sessionDev,
    likwidMemeTG = 'https://t.me/likwid_fi',
    topSocialLinks = [
        ['twitter', 'https://x.com/likwid_fi'],
        ['telegram', likwidMemeTG]
    ],
    socialLinks = [
        ['medium', 'https://medium.com/@likwidofficial'],
        ...topSocialLinks,
        ['github', 'https://github.com/likwid-fi']
    ],
    {location, open: windowOpen} = window,
    windowReplace = path => location.href = path,
    chains = [
        ...memeSlaveContracts,
        ...ternaryArray(portfolioPrdOpen && location.pathname.slice(1).split('/')[0] === 'portfolio', [masterChain])
    ],
    defaultChain = chains[0],
    chainIds = chains.map(v => v.id),
    initData = {},
    projectId = '2006745ac580b61f1610d79f53496c97',
    // wagmiConfig = createConfig({
    //     autoConnect: true,
    //     connectors: w3mConnectors({chains, projectId, version: 2}),
    //     publicClient: configureChains(chains, [w3mProvider({projectId})]).publicClient,
    // }),
    // wagmiConfig = createConfig({
    //     autoConnect: true,
    //     connectors: w3mConnectors({chains, projectId, version: 2}),
    //     publicClient: configureChains(chains, [
    //         jsonRpcProvider({
    //             rpc: chain => ({
    //                 http: require(`./rpc/${chain.id}`).default
    //             })
    //         })
    //     ]).publicClient,
    // }),
    wagmiConfig = (() => {
        const dWC = defaultWagmiConfig({
            chains,
            projectId,
            metadata: {
                name: 'LIKWID',
                description: 'LIKWID',
                url: 'https://likwid.meme',
                icons: ['https://likwid.meme/logo.png'],
            },
            enableAnalytics: true,
        })
        dWC.setPublicClient(configureChains(chains, [
            jsonRpcProvider({
                rpc: chain => ({
                    http: require(`./rpc/${chain.id}`).default
                })
            })
        ]).publicClient)
        return dWC
    })(),
    ethereumClient = new EthereumClient(wagmiConfig, chains),
    height = '.6rem',
    heightPhone = '2rem',
    leaveOut = (str, start = 2, end = 4) => str ? [str.slice(0, start), str.slice(str.length - end)].join('...') : '',
    // coins = [
    //     {
    //         name: 'ORBGUY',
    //         wantTotalDeposit: sessionDev ? 0.002 : 10,
    //         totalSupply: sessionDev ? 20000 : 20000000,
    //     },
    //     // 13
    //     // {
    //     //     name: 'LIKWID',
    //     //     wantTotalDeposit: 0.002,
    //     //     totalSupply: 20000,
    //     // }
    // ].map(v => ({
    //     ...v,
    //     uint: `$${v.name}`
    // })),
    timeSlots = [
        ['24H', 1, 24],
        ['3D', 3, 50],
        ['1W', 7, 90],
        ['ALL', undefined, 360],
    ],
    // quotaData = ['Quota', 'quota', [
    //     'Primary  xy = (x + Δx)(y + Δy)',
    //     'Multiplicative  xy ^2= z',
    //     'Shortable Token'
    // ]],
    howToVoteData = ['How to vote', 'howToVote', [
        'Telegram voting',
        'On-chain voting'
    ]],
    percentage = (num = 0) => `${npTimes(num, 100)}%`,
    airdrop04 = .04,
    tokenomicsList = (earmarkedPresale = 0, presale = 0) => [
        [
            [.5, 'pool locked in trading curve'],
            [.5, 'presale'],
        ],
        [
            [.5, 'pool locked in trading curve'],
            [.4, 'presale'],
            [.1, 'earmarked presale for airdrop'],
        ],
        [
            [.5, 'pool locked in trading curve'],
            [.45, 'presale'],
            (() => {
                const earmarkedPresale = .05
                return [
                    earmarkedPresale,
                    `earmarked presale (${percentage(npMinus(earmarkedPresale, airdrop04))} for creator, ${percentage(airdrop04)} for airdrop)`
                ]
            })(),
        ],
        (() => {
            const tradingCurve = .5, airdrop = .05
            return [
                [tradingCurve, 'pool locked in trading curve'],
                [1 - tradingCurve - airdrop - earmarkedPresale, `presale, ${percentage(earmarkedPresale)} earmarked presale`],
                [
                    airdrop,
                    'airdrop'
                ],
            ]
        })(),
        [
            [.1, 'presale'],
            [.9, 'pool locked in trading curve'],
        ],
        [
            [presale, 'presale'],
            [1 - presale, 'pool locked in trading curve'],
        ],
    ],
    tokenomicsListLength = tokenomicsList().length,
    tokenomicsTextList = (...params) => tokenomicsList(...params).map(
        v => v.map(v => [`${percentage(checkData0(v, 0))}`, v[1]])
    ),
    tokenomicsData = (...params) => ['Tokenomics', 'tokenomics',
        tokenomicsTextList(...params).map(v =>
            v.map(v => v.join(' ')).join(', ')
        )
    ],
    defaultTokenomicsNum = 6,
    defaultTokenomicsIndex = defaultTokenomicsNum - 1,
    airdropProportion = (tokenomics, ...params) => tokenomics === defaultTokenomicsIndex ? airdrop04 : checkData0(tokenomicsList(...params), tokenomics, 2, 0),
    defaultTokenomicsPercentage = (...params) => checkData([tokenomicsTextList(...params), defaultTokenomicsIndex, 2, 0]),
    isTokenomicsAuto = tokenomics => tokenomics === 4,
    tgUrl = 'https://t.me/',
    xUrl = 'https://x.com/',
    totalPointsText = `What are DROPLETS?
Drip drip drip... What's that sound? It's the sound of us leaking this information to you guys... Behold, Likwid's Points System!

DROPLETS are the loyalty points of the LIKWID platform. The more points you accumulate, the higher the proportion of Likwid tokens you will receive in our future airdrops.`,
    contractStatuData = () => JSON.parse(store.getState().contractStatuData),
    withdrawIndex = 1,
    defaultAuthorizePoints = 110,
    twitterAuthorizePoints = defaultAuthorizePoints,
    tGAuthorizePoints = defaultAuthorizePoints,
    twitterAuthorizeData = {
        authorizeKey: 'x',
        authorizePoints: twitterAuthorizePoints,
        title: 'Follow & Share',
        needBind: true,
    },
    tGAuthorizeAuthorizeKey = 'tg',
    {authorizeKey: twitterAuthorizeAuthorizeKey} = twitterAuthorizeData,
    isTwitterAuthorizeAuthorize = aK => aK === twitterAuthorizeAuthorizeKey,
    pingFeeFunctionName = ['vote', 'create', 'crossTo', 'ethCrossTo'],
    buySellFunctionNames = ['swapExactETHForTokens', 'swapExactTokensForETH', 'buy', 'sell'],
    pongFeeFunctionName = ['claim', 'deposit', 'claimFee', ...buySellFunctionNames],
    isNewMemeFunc = coinName => coinName !== 'ORBGUY',
    isNewMemeNoGATOFunc = coinName => isNewMemeFunc(coinName) && coinName !== 'GATO',
    isNewMemeNoTAIBOIFunc = coinName => isNewMemeNoGATOFunc(coinName) && coinName !== 'TAIBOI',
    gasMultiple = 5,
    xCodeSplit = '##',
    commonChainTime = 5000,
    crossChainTimeSeconds = 30,
    pingCrossChainTime = 20000,
    depositTotalPrompt = 'Top-up limit on the omni chain: ',
    addressZero = '0x0000000000000000000000000000000000000000',
    contestLaunchFunds = '0.3',
    presaleList = [
        [10, 1], [20, 3], [30, 5], [40, 7], [50, 10],
    ],
    maxSlippageMin = 0.1,
    maxSlippageMax = 10,
    Oracles = [
        ['No Oracle', 'No Oracle', `Liquidation based on LP pair prices 
Long-tail assets can be listed without approval`],
        ['LP-Friendly', 'LP-Friendly', `In addition to trading fees, liquidity providers can also receive leverage interest and liquidation penalties.`],
        ['Project-Friendly', 'Project-Friendly', 'Provides on-chain project teams with a new dimension for market value management.'],
        ['Anti-Arbitrage and MEV', `Anti-Arbitrage   
and MEV`, 'Redistributes MEV to leverage traders through dynamic fees.'],
    ],
    formula1 = `In long trading operations, users acquire Y using X.
The protocol’s pricing mechanism follows the formula x(y+y′)=k,

where:
x: Represents the amount of X.
y: Represents the target ERC-20 token quantity.
y′: A mapped derivative token created by the protocol.`,
    likwidFormula = [
        ['Uniswap v2', 'K = 20 * 100', 100],
        ['Likwid formula', 'K = 20 * ( 90 + 10 )', 90, 'y’=10']
    ],
    Roadmaps = [
        ['2024 Q4', `Kickoff 
Team Construction
Product Prototype
Testnet Launch`],
        ['2025 Q1', `Security Audit
Launch β Version on Mainnet
V1 Version Testnet Launch`],
        ['2025 Q2', `V1 Version Mainnet Launch
DAO Governance & Voting
V2 Product Conception
V2 Version Testnet Launch`],
        ['2025 Q3', `V2 Version Mainnet Launch
Token Economics Proposals`],
    ],
    lkwidHook = [
        ['Singleton contract', 'Provide important gas savings'],
        ['Flash accounting( EIP-1153)', 'Bring cleaner contract designs'],
        ['Native ETH', 'Additional gas savings'],
    ],
    Profile = connectClass(props => {
            const pathname = location.pathname.slice(1),
                pNs = pathname.split('/'),
                pNs0 = pNs[0],
                pNs1 = pNs[1],
                isHome = pNs0 === '',
                isContest = pNs0 === 'contest',
                isAbout = pNs0 === 'about',
                isLaunch = launchPrdOpen && pNs0 === 'launch',
                isSwap = swapPrdOpen && pNs0 === 'swap',
                isPortfolio = portfolioPrdOpen && pNs0 === 'portfolio',
                isVotePage = votePrdOpen && pNs0 === 'vote',
                showPath = isContest ? '' : pNs0,
                [messageApi, contextHolder] = message.useMessage(),
                toast = content => messageApi.open({content, style: {marginTop: '1.5rem'}}),
                toastEmpty = () => toast('Amount cannot be empty'),
                toastSuccessful = success => toast(success ? 'Successful' : 'Failed'),
                toastSuccessfulHash = success => toast(success ? 'The transaction is on-chain; please wait for completion.' : 'Failed'),
                toastVerifyHuman = () => toast('Verify you are human'),
                copyText = (text = '', func) => {
                    const input = document.createElement('input')
                    input.value = text
                    document.body.appendChild(input)
                    input.select()
                    document.execCommand('Copy')
                    document.body.removeChild(input)
                    isNotNull(func) ? func() : toastSuccessful(true)
                },
                minBigInt = 0.000001,
                parseEtherWei = num => {
                    const numNumber = Number(num)
                    return parseEther(((isNaN(numNumber) || numNumber < minBigInt) ? 0 : num).toString())
                },
                numberETH = (numWei = '0') => numberPointFixed(formatEther(numWei)),
                numberFormatEther = (numWei = '0') => Number(formatEther(numWei)),
                numberParseEtherWei = (numETH = '0') => Number(parseEtherWei(numETH)),
                {isPhoneClientWidth} = store.getState(), [data, stateData] = useState({...initData}),
                setData = (d, clean) => stateData(data => ({...(clean ? {} : data), ...d})),
                toggleShowNotYet = showNotYet => setData({showNotYet}),
                toggleShowNotYetTrue = () => toggleShowNotYet(true),
                // noContractMemes = checkDataArray(data, 'noContractMemes'),
                pNsSymbol = (() => {
                    const pNSStr = checkDataString(pNs1)
                    return isNotNullOrEmpty(pNSStr)
                        ? pNSStr
                        : ((isSwap || isPortfolio) && !isNotNullOrEmpty(checkData([currentCoin(), 'symbol'])) ? 'SHORTHAIR' : pNSStr)
                })(),
                pNsSymbolUpperCase = pNsSymbol.toLocaleUpperCase(),
                pNsSymbolIsNotNullOrEmpty = isNotNullOrEmpty(pNsSymbol),
                pNsSymbolIsNotExist = checkData([data, 'pNsSymbolIsNotExist']),
                cC = (() => {
                    const obj = currentCoin(),
                        symbol = checkDataString(obj, 'symbol'),
                        pNS = symbol.toLocaleUpperCase() === pNsSymbolUpperCase ? symbol : pNsSymbol
                    return {
                        ...obj,
                        ...ternaryObject(
                            !pNsSymbolIsNotExist && pNsSymbolIsNotNullOrEmpty,// && !noContractMemes.includes(symbol),
                            {symbol: pNS, name: pNS}
                        )
                    }
                })(),
                loadCoins = checkDataArray(data, 'coins'),
                cCIsIncludes = loadCoins.map(v => checkDataString(v, 'name').toLocaleUpperCase()).includes(checkDataString(cC, 'name').toLocaleUpperCase()),
                isLaunchSearch = checkDataBool(data, 'isLaunchSearch'),
                cCIsNotNull = isNotNullOrEmpty(Object.keys(cC)) && (isLaunchSearch || cCIsIncludes),
                coins = [
                    ...ternaryArray(cCIsNotNull && !cCIsIncludes && !isLaunchSearch && !isHome, [cC]),
                    ...loadCoins
                ],
                catTip = (toggleFunc, show, title, text) => show &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.7)',
                         }}>
                        <div style={{width: `${isPhoneClientWidth ? 20 : 8}rem`}}
                             className={'positionRelative flexColumnCenter'}>
                            {isNotNull(toggleFunc) && <div className={'width100 flex justifyContentFlexEnd'}>
                                <img className={'cursorPointer'}
                                     onClick={() => checkDataFunction(toggleFunc)()}
                                     src={requireDefault('closeWhite')} alt=""
                                     style={{width: '5%'}}/>
                            </div>}
                            <img className={'zIndex1'}
                                 style={{width: '70%', marginBottom: `${isPhoneClientWidth ? -2.1 : -.7}rem`}}
                                 src={requireDefault('cat5')} alt=""/>
                            <div
                                className={`width100 ${isPhoneClientWidth
                                    ? 'childrenSpacingTopPhone boxSizingBorderBox borderRadiusPhone'
                                    : 'childrenSpacingTopBig boxSizingBorderBox'} flexColumnCenter boxSizingBorderBox`}
                                style={isPhoneClientWidth
                                    ? {
                                        padding: '2.1rem',
                                        borderRadius: '1.5rem',
                                        border: 'solid .15rem white',
                                        background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                    }
                                    : {
                                        padding: '.7rem',
                                        borderRadius: '.5rem',
                                        border: 'solid .05rem white',
                                        background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                    }}
                            >
                                {title && <div className={`saf ${isPhoneClientWidth ? '' : 'bigFontSize'} textAlignCenter`}>
                                    {title}
                                </div>}
                                {isNotNullOrEmpty(text) && <pre
                                    className={`textAlignCenter ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}>{text}</pre>}
                            </div>
                        </div>
                    </div>,
                showDeployment = checkDataBool(data, 'showDeployment'),
                toggleShowDeployment = showDeployment => setData({showDeployment}),
                {
                    wantTotalDeposit = 0,
                    name: currentCoinName,
                    uint: currentCoinUint,
                    totalSupply: currentCoinTotalSupply,
                    launchFunds: currentCoinLaunchFunds,
                    hardcap: currentCoinHardcap,
                    launchCountdown: currentCoinLaunchCountdown,
                    logo: currentCoinLogo,
                    desc: currentCoinDesc,
                    tg: currentCoinTG,
                    x: currentCoinX,
                    tokenomics: currentCoinTokenomics,
                    quota: currentCoinQuota,
                    presale: currentCoinPresale,
                    creator: currentCoinCreator,
                    version: currentCoinAbiVersion,
                    addressList: currentCoinContracts = {},
                    initialDate: currentCoinInitialDate,
                    launchTime: currentCoinLaunchTime,
                    cmCap: currentCoinCMCap,
                    holders: currentCoinHolders,
                    enableShort: currentEnableShort,
                    longSymbol: currentCoinLongSymbol,
                    shortSymbol: currentCoinShortSymbol,
                    shortMasterAbi: currentShortMasterAbi,
                    shortMasterAddress: currentShortMasterAddress,
                    longMasterAbi: currentLongMasterAbi,
                    longMasterAddress: currentLongMasterAddress,
                    initialPrice: currentCoinInitialPrice,
                } = cCIsNotNull ? cC : checkDataObject(coins, 0),
                currentCoinNameIsNotNull = isNotNull(currentCoinName),
                cCTgIsNotNull = isNotNullOrEmpty(currentCoinTG),
                cCXIsNotNull = isNotNullOrEmpty(currentCoinX),
                longShortTrue = currentEnableShort || currentCoinLongSymbol,
                notYet = checkDataBool(data, 'showNotYet') &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.7)',
                         }}>
                        {
                            (() => {
                                const notYetWidth = isPhoneClientWidth ? 20 : 8
                                return [
                                    <div className={'flex justifyContentFlexEnd'} style={{width: `${notYetWidth}rem`}}>
                                        <img className={'cursorPointer'}
                                             onClick={() => toggleShowNotYet()}
                                             src={requireDefault('closeWhite')} alt=""
                                             style={{width: `${npDivide(notYetWidth, 20)}rem`}}/>
                                    </div>,
                                    <img src={requireDefault('notYet')} alt=""
                                         style={{width: `${notYetWidth}rem`}}/>
                                ]
                            })()
                        }
                    </div>,
                drip = catTip(undefined, !sessionDev && checkDataBool(data, 'showDrip'), 'DRIPI DRIP!', checkDataString(data, 'dripText')),
                navigations = [
                    ['home', () => windowReplace('/'), 31],
                ],
                devCount = checkData0(data, 'devCount'),
                deposit = checkData0(data, 'deposit'),
                omniChain = checkData0(data, 'omniChain'),
                numberETHOmniChain = numberETH(omniChain),
                ethBalance = checkData0(data, 'ethBalance'),
                formChartIndex = checkData0(data, 'formChartIndex'),
                isShowChart = formChartIndex === 0,
                tokenBalance = checkData0(data, 'tokenBalance'),
                estimatedInternetFees = checkDataString(data, 'estimatedInternetFees'),
                isSwapBuy = checkData([data, 'isSwapBuy'], true),
                omniDeposited = checkData0(data, 'omniDeposited'),
                isNewMeme = isNewMemeFunc(currentCoinName),
                isNewMemeNoGATO = isNewMemeNoGATOFunc(currentCoinName),
                isNewMemeNoTAIBOI = isNewMemeNoTAIBOIFunc(currentCoinName),
                isNewMemeNoUTKOrPUPPET = !currentCoinNameIsNotNull || (isNewMemeNoTAIBOI && currentCoinAbiVersion >= 5),
                isV7 = currentCoinAbiVersion >= 7 || currentCoinLongSymbol,
                isV8 = currentCoinAbiVersion >= (currentCoinLongSymbol ? 2 : 8),
                isMaxSlippage = isSwap && isV8,// && !currentCoinLongSymbol,
                depositWithdraw = checkData0(data, 'depositWithdraw'),
                isWithdraw = depositWithdraw === withdrawIndex,
                isLaunchSwapBuy = isLaunch ? !isWithdraw : isSwapBuy,
                signTrue = checkData([data, 'signTrue']),
                signTrueString = checkDataString(signTrue),
                swapLaunched = checkDataBool(data, 'launched'),
                buySignTrue = isNewMemeNoUTKOrPUPPET && isLaunchSwapBuy,
                signSwitch = checkDataBool(data, 'signSwitch'),
                buyMaxNoGas = buySignTrue && signSwitch && (!isSwap || !swapLaunched),
                buyCheckSignTrue = buyMaxNoGas && !signTrue,
                reserves = checkDataArray(data, 'reserves'),
                reserves0 = checkData0(reserves, 0),
                reserves1 = checkData0(reserves, 1),
                shortCurrentPrice = checkData0(data, 'shortCurrentPrice'),
                maxETHBalance = (() => {
                    const defaultMaxETHBalance = 5,
                        isDepositTotal = (isLaunch && isNewMemeNoTAIBOI && !isWithdraw) || (isSwap && buyCheckSignTrue),
                        mEB = (isSwap && !isSwapBuy)
                            ? defaultMaxETHBalance
                            : npMinus(
                                checkData([data, isDepositTotal ? 'depositTotal' : 'maxETHBalance'], defaultMaxETHBalance),
                                isDepositTotal ? omniDeposited : 0
                            ),
                        mEB1 = checkData0(data, 'paymentNativeMax')
                    return currentCoinLongSymbol && isSwap && isSwapBuy && (mEB1 < mEB)
                        ? mEB1
                        : mEB
                })(),
                getContractAbi = (chainId, version = currentCoinAbiVersion, cCS = currentCoinLongSymbol) => axios(`${sessionDev
                    ? 'https://test-s3.likwid.meme'
                    : 'https://s3.likwid.meme'}/${cCS ? 'short' : 'product'}-abi/v${version}/${cCS ? 'Short' : 'Token'}${isMasterContractFunc(chainId) ? 'Master' : 'Slave'}.json`).then(v => checkDataObject(v, 'data')),
                sendETHBalance = ethBalance > maxETHBalance ? maxETHBalance : ethBalance,
                parseEtherWeiEthBalance = parseEtherWei(sendETHBalance),
                timeSlotIndex = checkData([data, 'timeSlotIndex'], 3),
                {address} = useAccount(),
                getClaimed = () => rC('claimed', address),
                devCountAdd = () => setData({
                    devCount: devCount + 1
                }),
                showList = checkDataBool(data, 'showList'), toggleShowList = () => setData({showList: !showList}),
                {
                    isOpen,
                    open = () => {
                    },
                    close = () => {
                    },
                } = checkDataObject(useWeb3Modal()),
                connectLoading = width => checkDataBool(data, 'showConnectLoading') &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.56)',
                         }}>
                        <img src={requireDefault('loading')} alt="" style={{width: `${width}rem`}}/>
                    </div>,
                currentChainId = checkData([ethereumClient.getNetwork().chain, 'id'], undefined),
                wrongNetwork = !chainIds.includes(Number(currentChainId)),
                chainValue = wrongNetwork ? defaultChain : ethereumClient.getNetwork().chain,
                chainId = checkData([chainValue, 'id'], defaultChain.id),
                contractStatus = checkDataObject(contractStatuData(), chainId, address),
                contractStatusKeys = () => Object.keys(contractStatus).reverse(),
                pingPongDone = (key, tx, obj) => {
                    obj = isNotNull(obj) ? obj : checkDataObject(contractStatus, tx, key)
                    return checkData([obj, 'status']) === 2
                        && checkData([obj, 'opStatus']) === 99
                        && isNotNullOrEmpty(checkData([obj, 'targetId']))
                },
                pingDone = tx => pingPongDone('ping', tx),
                pongDone = tx => pingPongDone('pong', tx),
                contractStatuList = (() => {
                    const cSc = contractStatusKeys().slice(0, 3),
                        showAllCSc = checkData([data, 'showAllCSc'], true),
                        arr = showAllCSc ? cSc : [...cSc].slice(0, 1)
                    return isNotNullOrEmpty(arr) &&
                        <div style={{background: 'linear-gradient(180deg, #FF7788 0%, #FFB9C2 100%)'}}
                             className={`${isPhoneClientWidth ? 'paddingBigger borderRadiusPhone' : 'padding borderRadius'} overflowVisible`}>
                            <div
                                className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                <div
                                    className={`saf colorWhite ${isPhoneClientWidth ? 'bigFontSizePhone' : 'bigFontSize'}`}>POWERED
                                    BY
                                </div>
                                {
                                    [
                                        'ORBITER FINANCE',
                                        'chainIcons/28518'
                                    ].map(v => <img src={requireDefault(v)} alt=""
                                                    style={{width: `${isPhoneClientWidth ? 1.2 : .4}rem`}}/>)
                                }
                                <img className={isPhoneClientWidth ? 'marginLeftBigPhone' : 'marginLeftBig'}
                                     src={requireDefault('start1')} alt=""
                                     style={{width: `${isPhoneClientWidth ? 1.8 : .6}rem`}}/>
                            </div>
                            <div className={isPhoneClientWidth ? 'childrenSpacingTopBig' : 'childrenSpacingTop'}>
                                {arr.map((tx, i) => {
                                    const piD = pingDone(tx),
                                        poD = pongDone(tx),
                                        showMoreIcon = cSc.length > 1 && i === 0
                                    return <div>
                                        {showMoreIcon &&
                                            <div
                                                className={`flex justifyContentFlexEnd ${isPhoneClientWidth ? 'paddingRightPhone' : 'paddingRight'}`}>
                                                <img className={'backgroundWhite cursorPointer'}
                                                     onClick={() => setData({showAllCSc: !showAllCSc})}
                                                     src={requireDefault(showAllCSc ? 'contractStatuTop' : 'contractStatuBottom')}
                                                     alt=""
                                                     style={{
                                                         padding: `0 ${isPhoneClientWidth ? .5 : .1}rem`,
                                                         borderTopRightRadius: `${isPhoneClientWidth ? .21 : .07}rem`,
                                                         borderTopLeftRadius: `${isPhoneClientWidth ? .21 : .07}rem`,
                                                         ...square(`${isPhoneClientWidth ? .8 : .2}rem`)
                                                     }}/>
                                            </div>}
                                        <div
                                            className={`${isPhoneClientWidth
                                                ? 'boxSizingBorderBox'
                                                : 'padding paddingTopBig childrenSpacingTopBig'} positionRelative`}
                                            style={{
                                                width: isPhoneClientWidth ? '15rem' : '5rem',
                                                borderRadius: `${isPhoneClientWidth ? .6 : .2}rem`,
                                                border: `${isPhoneClientWidth ? .15 : .05}rem solid white`,
                                                background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 100%)',
                                                ...ternaryObject(
                                                    isPhoneClientWidth,
                                                    {padding: '.7rem .5rem .5rem .5rem'}
                                                )
                                            }}>
                                            <div className={'flexCenter'}>
                                                <div style={{width: isPhoneClientWidth ? '90%' : '85%'}}
                                                     className={'flexCenter positionRelative'}>
                                                    <div className={'flexCenter childrenFlexGrow1'}
                                                         style={{width: '95%'}}>
                                                        {
                                                            [true, piD].map(v => <div
                                                                style={{
                                                                    height: `${isPhoneClientWidth ? .3 : .1}rem`,
                                                                    background: v ? '#FF7789' : 'white'
                                                                }}/>)
                                                        }
                                                    </div>
                                                    <div style={{top: 0, bottom: 0, left: 0, right: 0}}
                                                         className={'positionAbsolute flexCenter justifyContentSpaceBetween width100'}>
                                                        {
                                                            [true, piD, poD].map(v =>
                                                                <img
                                                                    src={requireDefault(v ? 'contractStatuDone' : 'contractStatuNotDon')}
                                                                    alt=""
                                                                    style={{width: isPhoneClientWidth ? '7%' : '10%'}}/>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{
                                                lineHeight: .7,
                                                ...ternaryObject(
                                                    isPhoneClientWidth,
                                                    {marginTop: '.7rem'}
                                                )
                                            }}
                                                 className={'flexCenter justifyContentSpaceBetween childrenFlexGrow1'}>
                                                {
                                                    (() => {
                                                        const arr = ['Ordered', 'Processing', 'Completed']
                                                        return arr.map((v, i) => <div
                                                            style={ternaryObject(isPhoneClientWidth, {fontSize: '.5rem'})}
                                                            className={`${isPhoneClientWidth ? '' : 'smallerFontSize'} ${i === 0 ? '' : (i < arr.length - 1 ? 'textAlignCenter' : 'textAlignRight')}`}>{v}</div>)
                                                    })()
                                                }
                                            </div>
                                            {/*{showMoreIcon && isPhoneClientWidth &&*/}
                                            {/*    <img className={'backgroundWhite cursorPointer positionAbsolute'}*/}
                                            {/*         onClick={() => setData({showAllCSc: !showAllCSc})}*/}
                                            {/*         src={requireDefault(showAllCSc ? 'contractStatuTop' : 'contractStatuBottom')}*/}
                                            {/*         alt=""*/}
                                            {/*         style={{*/}
                                            {/*             top: 0,*/}
                                            {/*             right: '-1.5rem',*/}
                                            {/*             padding: '0 .2rem',*/}
                                            {/*             borderRadius: '.21rem',*/}
                                            {/*             ...square('.8rem')*/}
                                            {/*         }}/>}*/}
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                })(),
                toggleShowLoading = showLoading => setData({showLoading}),
                showLoading = checkDataBool(data, 'showLoading'),
                loading = showLoading &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.56)',
                         }}>
                        <img src={requireDefault('loading')} alt="" style={{width: `${isPhoneClientWidth ? 10 : 5}rem`}}/>
                    </div>,
                token = () => sessionStorage.getItem('token'),
                requestApi = (url, data, method, headers = {}) => {
                    const t = token()
                    return axios(
                        {
                            method,
                            url: `${sessionDev ? 'https://test-api.likwid.meme/v1/' : 'https://api.likwid.meme/v1/'}${url}`,
                            [method ? 'data' : 'params']: data,
                            headers: {
                                ...headers,
                                ...ternaryObject(t, {'Authorization': `Bearer ${t}`})
                            },
                        },
                    ).then(r => {
                        const d = checkDataObject(r, 'data'),
                            isSuccess = d.code == '1'
                        if (!isSuccess) {
                            toast(d.msg)
                            toggleShowLoading()
                        }
                        return isSuccess ? checkDataObject(d, 'data') : null
                    }).catch(e => {
                        toggleShowLoading()
                        toast('Unknown error')
                        console.error(`------------------------------\n`, e, `------------------------------\n`)
                    })
                },
                loginThenFunc = (func = () => {
                }, errorFunc = () => {
                }) => token()
                    ? func()
                    : new Promise(resolve => requestApi('login/code', {address}).then(r => {
                            setTimeout(() => {
                                const code = checkData([r, 'code'])
                                signMessage({message: code}).then(sign =>
                                    requestApi(
                                        'login/login',
                                        {
                                            code,
                                            sign,
                                            address,
                                            loginType: 'WEB',
                                        },
                                        'post'
                                    ).then(r => {
                                            const token = checkData([r, 'token'])
                                            if (token) {
                                                sessionStorage.setItem('token', token)
                                                resolve(func())
                                            }
                                        }
                                    )
                                ).catch(e => {
                                    errorFunc()
                                    toast(checkDataString(checkDataString(e, 'message').split('\n'), 0))
                                })
                            }, 1000)
                        })
                    ),
                isMasterContract = isMasterContractFunc(currentChainId),
                isBNBFunc = id => [bnbTestnet, bnb].map(v => v['id']).includes(Number(id)),
                isBNB = isBNBFunc(chainId),
                ethBNB = isBNB ? 'BNB' : 'ETH',
                estimatedInternetFeesShow = [['Estimated Internet Fees', `${estimatedInternetFees} ${ethBNB}`]],
                maxETHBalanceToast = () => toast(`The transaction amount cannot be greater than ${maxETHBalance} ${ethBNB} ！`),
                fM = isMasterContractFunc(chainId),
                chainName = checkData([chainValue, 'name'], defaultChain.name),
                deployment = catTip(
                    toggleShowDeployment,
                    showDeployment,
                    undefined,
                    `${currentCoinName} does not support ${chainName} yet, please switch to other networks`,
                ),
                addressIsNotNull = isNotNull(address),
                addressLeaveOut = leaveOut(address),
                createMemeContractSlave = require(`./createMemeContract${sessionDev ? 'Dev' : ''}/${chainId}`).default,
                slaveContract = isVotePage
                    ? createMemeContractSlave
                    : checkDataObject(data, 'slaveContract'),
                // require(`./slaveContract${sessionDev ? 'Dev' : ''}/${'ORBGUY'}/${chainId}`).default
                createMemeContractMaster = require(`./createMemeContract${sessionDev ? 'Dev' : ''}/master`).default,
                masterContract = isVotePage
                    ? createMemeContractMaster
                    : checkDataObject(data, 'masterContract'),
                rewardClaimContract = require(`./rewardClaimContract${sessionDev ? 'Dev' : ''}`).default,
                // createBlockNumber = checkData([masterContract, 'receipt', 'blockNumber']),
                // require(`./masterContract${sessionDev ? 'Dev' : ''}/${'ORBGUY'}/prd`).default
                {eth: masterContractETH} = new Web3(
                    checkData([masterContract, 'rpc'], masterChain.rpcUrls.default.http[0])
                ),
                {eth: slaveContractETH} = new Web3(require(`./rpc/${chainId}`).default),
                {eth: rewardClaimContractETH} = new Web3(rewardClaimContract.rpc),
                {address: slaveContractAddress, abi: slaveContractAbi} = slaveContract,
                {
                    address: masterContractAddress,
                    abi: masterContractAbi,
                    blockExplorersTX: masterContractBlockExplorersTX
                } = masterContract,
                {
                    address: createMemeContractMasterAddress,
                    abi: createMemeContractMasterAbi,
                } = createMemeContractMaster,
                {
                    address: rewardClaimContractAddress,
                    abi: rewardClaimContractAbi,
                } = rewardClaimContract,
                {
                    address: createMemeContractSlaveAddress,
                    abi: createMemeContractSlaveAbi,
                } = createMemeContractSlave,
                contestContract = require(`./contestContract${sessionDev ? 'Dev' : ''}`).default,
                {
                    address: contestContractAddress,
                    abi: contestContractAbi,
                } = contestContract,
                bscSwapContract = require(`./bscSwapContract${sessionDev ? 'Dev' : ''}`).default,
                {
                    address: bscSwapContractAddress,
                    abi: bscSwapContractAbi,
                } = bscSwapContract,
                channelContractSlave = require(`./channelContract${sessionDev ? 'Dev' : ''}/${chainId}`).default,
                {
                    address: channelContractSlaveAddress,
                    abi: channelContractSlaveAbi,
                } = channelContractSlave,
                channelContractMaster = require(`./channelContract${sessionDev ? 'Dev' : ''}/master`).default,
                {
                    address: channelContractMasterAddress,
                    abi: channelContractMasterAbi,
                } = channelContractMaster,
                blockTimestamp = second => masterContractETH.getBlock().then(block => npTimes(Number(block.timestamp), second ? 1 : 1000)),
                ethUSDT = checkData([data, 'ethUSDT']),
                ethUSDTIsNotNull = isNotNull(ethUSDT),
                buySellEvent = checkDataArray(data, 'buySellEvent'),
                firstSwapPage = 1,
                swapPage = checkData([data, 'swapPage'], firstSwapPage),
                swapPageIsFirst = swapPage === firstSwapPage,
                setFistSwapPage = () => setData({swapPage: firstSwapPage}),
                addSwapPage = () => {
                    toggleShowLoading(true)
                    setData({swapPage: swapPage + 1})
                },
                masterContractETHGetEvents = async (eventName, page = swapPage, step = 10) => {
                    const lB = checkData0(lastBlock), fromBlock = npPlus(npMinus(lB, step * (page + 1)), 1),
                        toBlock = npMinus(lB, step * page)
                    return (new masterContractETH.Contract(masterContractAbi, masterContractAddress)).getPastEvents(
                        eventName,
                        {
                            fromBlock: fromBlock < 0 ? 0 : fromBlock,
                            toBlock: toBlock < 0 ? 0 : toBlock,
                        }
                    )
                },
                contractCall = (eth, address, abi, funcName, ...params) => {
                    const func = checkData([new eth.Contract(abi, address), 'methods', funcName])
                    return func ? func(...params).call() : new Promise(resolve => resolve())
                },
                masterContractCall = (...params) => contractCall(masterContractETH, masterContractAddress, masterContractAbi, ...params),
                createMemeContractMasterCall = (funcName, ...params) =>
                    contractCall(new Web3(createMemeContractMaster.rpc).eth, createMemeContractMasterAddress, createMemeContractMasterAbi,
                        funcName, ...params
                    ),
                createMemeContractSlaveCall = (funcName, ...params) =>
                    contractCall(slaveContractETH, createMemeContractSlaveAddress, createMemeContractSlaveAbi,
                        funcName, ...params
                    ),
                rewardClaimContractCall = (funcName, ...params) => contractCall(
                    rewardClaimContractETH,
                    rewardClaimContractAddress,
                    rewardClaimContractAbi,
                    funcName,
                    ...params
                ),
                contestContractCall = (funcName, ...params) =>
                    contractCall(slaveContractETH, contestContractAddress, contestContractAbi,
                        funcName, ...params
                    ),
                bscSwapContractCall = (funcName, ...params) =>
                    contractCall(slaveContractETH, bscSwapContractAddress, bscSwapContractAbi,
                        funcName, ...params
                    ),
                channelContractSlaveCall = (funcName, ...params) =>
                    contractCall(slaveContractETH, channelContractSlaveAddress, channelContractSlaveAbi,
                        funcName, ...params
                    ),
                channelContractMasterCall = (funcName, ...params) =>
                    contractCall(masterContractETH, channelContractMasterAddress, channelContractMasterAbi,
                        funcName, ...params
                    ),
                longMasterCall = (funcName, ...params) =>
                    contractCall(masterContractETH, currentLongMasterAddress, currentLongMasterAbi,
                        funcName, ...params
                    ),
                shortMasterCall = (funcName, ...params) =>
                    contractCall(masterContractETH, currentShortMasterAddress, currentShortMasterAbi,
                        funcName, ...params
                    ),
                bnbGetEstimated = (num, func) => isBNB ? bscSwapContractCall(func, num) : new Promise(resolve => resolve(num)),
                ethToBNB = num => bnbGetEstimated(num, 'getEstimatedNativeForWETH'),
                bnbToETH = num => bnbGetEstimated(num, 'getEstimatedWETHForNative'),
                defaultTokenomics = tokenomics => {
                    tokenomics = Number(tokenomics)
                    return npMinus(((tokenomics === 0 || tokenomics > tokenomicsListLength) ? defaultTokenomicsNum : tokenomics), 1)
                },
                contestContractRoundClaimAmount = symbol => contestContractCall('roundClaimAmount', symbol, address).then(amount => amount > 0),
                montageLink = (v, url) => isNotNullOrEmpty(v) ? `${url}${v.slice(v.lastIndexOf('/') + 1)}` : undefined,
                montageTGLink = v => v ? montageLink(v, tgUrl) : topSocialLinks[1][1],
                montageXLink = v => v ? montageLink(v, xUrl) : topSocialLinks[0][1],
                namePrefix = '[LIKWID] ',
                memeLogoFunc = logo => isNotNullOrEmpty(logo) ? memeLogo(logo) : requireDefault('LIKWID'),
                memeData = (meme, isApi) => {
                    const {symbol, logo, votes, quota, hardcap, totalSupply} = meme,
                        launchFunds = checkData0(meme, 'launchFunds'),
                        ethNumFunc = isNewMemeNoGATOFunc(symbol) ? numberETH : Number,
                        cmCapFunc = cmCap => `$${npDivide(checkData0(cmCap), 1000).toFixed(2)}K`,
                        xData = checkDataString(meme, 'x').split(xCodeSplit),
                        {shortMasterAbi, shortMasterAddress} = meme,
                        shortMasterAbiAddressIsTrue = shortMasterAbi && shortMasterAddress
                    return {
                        ...isApi
                            ? {
                                votes,
                                quota,
                                hardcap,
                                totalSupply,
                                launchFunds,
                                shortMasterAbi,
                                shortMasterAddress,
                                status: meme.status,
                                holders: meme.holders,
                                version: meme.version,
                                launched: meme.launched,
                                maxHolders: meme.maxHolders,
                                longSymbol: meme.longSymbol,
                                wantTotalDeposit: launchFunds,
                                initialDate: meme.initialDate,
                                initialPrice: meme.initialPrice,
                                longMasterAbi: meme.longMasterAbi,
                                longMasterAddress: meme.longMasterAddress,
                                cmCap: cmCapFunc(checkData0(meme, 'cmCap')),
                                maxCmCap: cmCapFunc(checkData0(meme, 'maxCmCap')),
                                addressList: checkDataObject(meme, 'addressList'),
                                shortSymbol: shortMasterAbiAddressIsTrue ? meme.shortSymbol : null,
                                launchTime: Math.floor(npDivide(checkData0(meme, 'launchTime'), 1000)),
                                enableShort: shortMasterAbiAddressIsTrue && checkDataArray(meme, 'shortSymbols').length > 0,
                            }
                            : {
                                votes: Number(votes),
                                quota: numberETH(quota),
                                hardcap: numberETH(hardcap),
                                totalSupply: ethNumFunc(totalSupply),
                                launchFunds: ethNumFunc(launchFunds),
                                wantTotalDeposit: ethNumFunc(launchFunds),
                            },
                        symbol,
                        name: symbol,
                        desc: (() => {
                            const name = checkDataString(meme, 'name'),
                                namePrefixNoSpace = namePrefix.replace(/\s+/g, '')
                            return name.indexOf(namePrefix) === 0 ? name.substring(namePrefix.length) : (
                                name.indexOf(namePrefixNoSpace) === 0 ? name.substring(namePrefixNoSpace.length) : name
                            )
                        })(),
                        uint: `$${symbol}`,
                        creator: meme.creator,
                        logo: memeLogoFunc(logo),
                        x: montageXLink(xData[0]),
                        tg: montageTGLink(meme.tg),
                        presale: npDivide(checkData0(xData, 1), 100),
                        tokenomics: defaultTokenomics(meme.tokenomics),
                        launchCountdown: npDivide(Number(meme.launchCountdown), 3600),
                    }
                },
                memeDataIsApi = (...params) => memeData(...params, true),
                searchSymbol = checkData([data, 'searchSymbol']),
                searchSymbolIsNotNull = isNotNullOrEmpty(searchSymbol),
                setDataSearchSymbolEmpty = () => setData({searchSymbol: ''}),
                shortMemeDetailFunc = symbol => requestApi(`token/short/${symbol}`),
                tokenMemeDetailFunc1 = symbol => requestApi(`token/meme/${symbol}`),
                tokenMemeDetailFunc = (symbol, cCLS = currentCoinLongSymbol1) => (isSwap && cCLS) ? shortMemeDetailFunc(symbol) : tokenMemeDetailFunc1(symbol),
                tokenContracts = symbol => requestApi(`token/contract/${symbol}`).then(addressList => checkDataObject(addressList)),
                getShortSymbol = async (cCN = currentCoinName, shortSymbols = [], needAddress) => {
                    // console.log(shortSymbols);
                    let shortSymbol = checkData([shortSymbols, 0, 'symbol'])
                    if (shortSymbol) {
                        try {
                            let shortMasterAddress = checkData([await tokenContracts(shortSymbol), masterChainId]),
                                shortMasterAbi = await getContractAbi(masterChainId, (await tokenMemeDetailFunc(shortSymbol, true)).version, true)
                            await (cCN === 'LSTD'
                                ? new Promise(reserves => reserves(true))
                                : contractCall(masterContractETH, shortMasterAddress, shortMasterAbi, 'checkCurrent')).then(async checkCurrent => {
                                    const shortSymbol1 = checkData([shortSymbols, 1, 'symbol'])
                                    // console.log(shortSymbol, shortMasterAddress, checkCurrent);
                                    if (!checkCurrent && isNotNull(shortSymbol1)) {
                                        shortSymbol = shortSymbol1
                                        if (needAddress) {
                                            shortMasterAddress = checkData([await tokenContracts(shortSymbol), masterChainId])
                                            shortMasterAbi = await getContractAbi(masterChainId, (await tokenMemeDetailFunc(shortSymbol, true)).version, true)
                                        }
                                    }
                                }
                            )
                            // console.log(shortSymbol, shortMasterAddress);
                            return {shortSymbol, shortMasterAddress, shortMasterAbi}
                        } catch {
                            return {}
                        }
                    } else {
                        return {}
                    }
                },
                tokenMemeDetail = symbol => tokenMemeDetailFunc(symbol).then(async obj => memeDataIsApi(
                        {
                            ...obj,
                            ...isSwap
                                ? await (async () => {
                                    const shortSymbols = checkDataArray(obj, 'shortSymbols')
                                    return checkData([shortSymbols, 0, 'symbol'])
                                        ? await getShortSymbol(checkDataString(obj, 'symbol'), shortSymbols, true)
                                        // ? await (async () => {
                                        //     // let shortMasterAbi, shortMasterAddress
                                        //     // if (shortSymbol) {
                                        //     //     shortMasterAddress = checkData([await tokenContracts(shortSymbol), masterChainId])
                                        //     //     shortMasterAbi = await getContractAbi(masterChainId, (await tokenMemeDetailFunc(shortSymbol, true)).version, true)
                                        //     //     await (currentCoinName === 'LSTD'
                                        //     //         ? new Promise(reserves => reserves(true))
                                        //     //         : contractCall(masterContractETH, shortMasterAddress, shortMasterAbi, 'checkCurrent')).then(async checkCurrent => {
                                        //     //             const shortSymbol1 = checkData([shortSymbols, 1, 'symbol'])
                                        //     //             if (!checkCurrent && isNotNull(shortSymbol1)) {
                                        //     //                 shortSymbol = shortSymbol1
                                        //     //                 shortMasterAddress = checkData([await tokenContracts(shortSymbol), masterChainId])
                                        //     //                 shortMasterAbi = await getContractAbi(masterChainId, (await tokenMemeDetailFunc(shortSymbol, true)).version, true)
                                        //     //             }
                                        //     //         }
                                        //     //     )
                                        //     // }
                                        //     // console.log(shortSymbol, shortMasterAddress, '+++++++++++++++');
                                        //     return ()
                                        // })()
                                        : await (async () => {
                                            const longSymbol = checkData([obj, 'longSymbol'])
                                            return longSymbol
                                                ? {
                                                    longMasterAddress: checkData([await tokenContracts(longSymbol), masterChainId]),
                                                    longMasterAbi: await getContractAbi(masterChainId, (await tokenMemeDetailFunc(longSymbol, false)).version, false)
                                                }
                                                : {}
                                        })()
                                })()
                                : {}
                        }
                    )
                ),
                symbolExists = checkDataBool(data, 'symbolExists'),
                toggleSymbolExists = symbolExists => setData({symbolExists}),
                setCurrentCoin = (currentCoin, isClick) => {
                    const {symbol} = currentCoin
                    return tokenMemeDetail(symbol).then(obj => tokenContracts(symbol).then(addressList => {
                        if (isClick
                            && checkDataString(symbol).toLocaleUpperCase() !== checkDataString(currentCoinName).toLocaleUpperCase()) {
                            windowReplace(`/${pNs0}/${symbol}`)
                        } else {
                            // console.log('setCurrentCoin', obj);
                            obj = {...obj, addressList}
                            const cCs = JSON.stringify({...currentCoins(), [pNs0]: obj})
                            sessionStorage.setItem('currentCoins', cCs)
                            dispatch(props, {currentCoins: cCs})
                        }
                    }))
                },
                setCoinIndex = coinIndex => {
                    const coin = checkDataObject(coins, coinIndex),
                        sCC = () => setCurrentCoin(coin, true)
                    isLaunch
                        ? sCC()
                        : tokenContracts(coin.name).then(
                            addressList =>
                                Object.keys(addressList).length > 0
                                    ? sCC()
                                    : toggleShowDeployment(true)
                        ).catch(() => toggleShowDeployment(true))
                },
                sortTypeIndex = checkData0(data, 'sortTypeIndex'),
                isPositiveSequence = checkDataBool(data, 'isPositiveSequence'),
                phaseId = checkData([data, 'phaseId']),
                phases = checkData([data, 'phases'], Array.from({length: 4}, (_, i) => ({id: i + 1}))),
                phaseIndex = npMinus(checkData([phaseId], 1), 1),
                isMemesCreated = checkDataBool(data, 'isMemesCreated'),
                claimFeeAmount = checkData0(data, 'claimFeeAmount'),
                claimFeeCoinsKey = 'claimFeeCoins',
                claimFeeCoins = checkDataArray(data, claimFeeCoinsKey),
                claimFeeCoin = checkDataObject(data, 'claimFeeCoin'),
                {symbol: claimFeeCoinSymbol} = claimFeeCoin,
                claimFeeCoinAddress = checkData([data, 'claimFeeCoinAddress']),
                claimFeeCoinsLengthKey = 'claimFeeCoinsLength',
                setClaimFeeCoin = (i = 0, coins1 = claimFeeCoins) => {
                    const claimFeeCoin1 = checkDataObject(coins1, i)
                    tokenContracts(claimFeeCoin1.symbol).then(addressList => setData({
                        claimFeeCoin: claimFeeCoin1,
                        claimFeeCoinAddress: addressList[masterChainId],
                    }))
                },
                showCreatedClaim = checkDataBool(data, 'showCreatedClaim'),
                claimFeeParams = [
                    undefined, undefined,
                    claimFeeCoinsPage, coinsPage => {
                        claimFeeCoinsPage = coinsPage + 1
                    }, undefined, claimFeeCoins, claimFeeCoinsKey, claimFeeCoinsLengthKey, true
                ],
                getClaimFeeAmount = () => createMemeContractMasterCall('getClaimFeeAmount', claimFeeCoinAddress, address).then(claimFeeAmount =>
                    setData({claimFeeAmount})
                ),
                getListedMemeList = (
                    pageSize = isHome ? 15 : 50, afterCreate,
                    cP = coinsPage, cPAdd = () => {
                        coinsPage = coinsPage + 1
                    }, orderBy, lC = loadCoins, coinsKey = 'coins', coinsLengthKey = 'coinsLength', isMemesCreated
                ) => {
                    const shortSwap = isSwap && currentCoinLongSymbol1,
                        gLL = (page = cP, pS = pageSize, symbol) => requestApi(
                            (isLaunch || isMemesCreated) ? 'token/symbol' : (shortSwap ? 'token/short/swap' : ((isHome || isContest || isVotePage) ? 'token/symbol/launch' : 'token/symbol/swap')),
                            {
                                page,
                                pageSize: pS,
                                ...ternaryObject(
                                    isNotNullOrEmpty(symbol),
                                    {symbol}
                                ),
                                ...ternaryObject(
                                    searchSymbolIsNotNull,
                                    {symbol: checkDataString(searchSymbol).toLocaleUpperCase()}
                                ),
                                ...ternaryObject(
                                    isHome || isContest,
                                    {orderBy: isNotNull(orderBy) ? orderBy : `${sortTypeIndex + 1}_${isPositiveSequence ? 'asc' : 'desc'}`}
                                ),
                                ...ternaryObject(
                                    isContest && isNotNull(listedPhaseId),
                                    {phaseId: listedPhaseId}
                                ),
                                ...ternaryObject(
                                    isMemesCreated,
                                    {creator: address}
                                ),
                                ...ternaryObject(
                                    shortSwap,
                                    {longSymbol: currentCoinLongSymbol1}
                                )
                            }
                        )
                    return gLL().then(
                        r => {
                            toggleShowLoading()
                            const arr = checkDataArray(r, 'items'),
                                isFirstCoinsPage = cP === firstCoinsPage,
                                includesPNsSymbol = arr => arr.map(v => checkDataString(v, 'symbol').toLocaleUpperCase()).includes(pNsSymbolUpperCase);
                            // console.log(isFirstCoinsPage, '+++++++++++');
                            return (isFirstCoinsPage && pNsSymbolIsNotNullOrEmpty && !includesPNsSymbol(arr) && !searchSymbolIsNotNull && !isContest && !isMemesCreated
                                ? tokenMemeDetailFunc(pNsSymbolUpperCase).then(v => Object.values(checkDataObject(v)).length > 0 ? [v] : [])
                                // ? gLL(firstCoinsPage, 1, pNsSymbolUpperCase).then(r1 => checkDataArray(r1, 'items').filter(v => checkDataString(v, 'symbol').toLocaleUpperCase() === pNsSymbolUpperCase))
                                : new Promise(resolve => resolve([]))).then(
                                async other => {
                                    // console.log(other, isLaunchSearch, '++++++++++++');
                                    const arr1 = [
                                            ...other,
                                            ...(isFirstCoinsPage ? arr : arr.filter(v => checkDataString(v, 'symbol').toLocaleUpperCase() !== pNsSymbolUpperCase))
                                        ].map(v => memeDataIsApi(v)),
                                        cCSymbol = checkDataString(cC, 'symbol').toLocaleUpperCase(),
                                        arr1IncludesSymbol = arr1.map(v => checkDataString(v, 'symbol').toLocaleUpperCase()).includes(cCSymbol)
                                    cPAdd(cP)
                                    // console.log('pNsSymbolIsNotExist', pNsSymbolIsNotNullOrEmpty && !includesPNsSymbol(arr1));
                                    const coins1 = [
                                        ...ternaryArray(!isFirstCoinsPage, lC),
                                        ...await Promise.all(arr1.map(async (v, i) => {
                                                const isFirst = i === 0
                                                // console.log(v.symbol, cCSymbol, !cCIsNotNull);
                                                if (!isHome && !isContest && (afterCreate ? isFirst : (arr1IncludesSymbol ? (checkDataString(v, 'symbol').toLocaleUpperCase() === cCSymbol) : (isFirst && !cCIsNotNull)))) {
                                                    // console.log(arr1IncludesSymbol, v, 'aaaaa', '++++++++++++=');
                                                    // console.log('getMemes update', v.symbol, arr1IncludesSymbol, cCSymbol);
                                                    await setCurrentCoin(v)
                                                }
                                                return v
                                            }
                                        ))
                                    ]
                                    if (isMemesCreated && isFirstCoinsPage) {
                                        claimFeeCoins1 = coins1
                                    }
                                    return setData({
                                        ...ternaryObject(
                                            !isNotNull(pNsSymbolIsNotExist),
                                            {pNsSymbolIsNotExist: pNsSymbolIsNotNullOrEmpty && !includesPNsSymbol(arr1)}
                                        ),
                                        ...ternaryObject(
                                            isFirstCoinsPage,
                                            {[coinsLengthKey]: checkData0(r, 'total')}
                                        ),
                                        [coinsKey]: coins1
                                    })
                                }
                            )
                        }
                    )
                },
                reGetListedMemeList = (...params) => {
                    coinsPage = firstCoinsPage
                    return getListedMemeList(...params)
                },
                setSearchSymbol = e => setData({searchSymbol: e.target.value}),
                searchSymbolOnKeyUp = (e, func = () => {
                }) => e.keyCode === 13 && func(),
                isChannelPongPing = handelType => isV8 && ['buy', 'sell'].includes(handelType),
                pingEstimateGas = (callFunc, handelType, amount, ...params) =>
                    (isChannelPongPing(handelType) ? channelContractSlaveCall : callFunc)(`${handelType}PingEstimateGas`, amount, ...params).then(pingFee => Number(pingFee)),
                rcPingEstimateGas = (...params) => pingEstimateGas(rC, ...params),
                bnbAmountMultiple = amount => isBNB ? parseEtherWei(npTimes(numberFormatEther(amount), 1.001)) : amount,
                getEstimateGas = (otherParams = [], needAmount, handelType, ...params) => {
                    const [a] = params, iCPP = isChannelPongPing(handelType)
                    return (['buy', 'deposit'].includes(handelType) ? bnbToETH(a) : new Promise(resolve => resolve(a))).then(amount => {
                        params = [
                            ...ternaryArray(iCPP, [currentCoinName]),
                            address,
                            ...ternaryArray(
                                needAmount,
                                [amount, ...params.slice(1)]
                            )
                        ]
                        const getParams = (index, isPong) => {
                            const params1 = [params[2], checkData([otherParams, index, 0])]
                            return iCPP
                                ? [...params.slice(0, 2), ...(isPong && handelType === 'sell') ? params1.reverse() : params1, 0]
                                : params
                        }
                        // console.log([...ternaryArray(isNewMeme && !iCPP, [0]), chainId, ...getParams(0, true)], [...getParams(1)], '-------------------');
                        return isMasterContract
                            ? new Promise(resolve => resolve([parseEtherWei(0), 0]))
                            : (iCPP ? channelContractMasterCall : masterContractCall)(`${handelType}PongEstimateGas`, ...ternaryArray(isNewMeme && !iCPP, [0]), chainId, ...getParams(0, true)).then(pongFee =>
                                (isBNB ? channelContractSlaveCall('exactOutput', pongFee) : new Promise(resolve => resolve(pongFee))).then(pongFee => {
                                    pongFee = bnbAmountMultiple(pongFee)
                                    return rcPingEstimateGas(handelType, pongFee, ...getParams(1)).then(pingFee => {
                                        // console.log('estimateGas', handelType, pongFee, pingFee);
                                        return [pongFee, Math.ceil(npPlus(Number(pongFee), pingFee))]
                                    })
                                })
                            )
                    })
                },
                getEstimateGasClaimFee = (handelType, ...params) => isMasterContract
                    ? new Promise(resolve => resolve([parseEtherWei(0), 0]))
                    : createMemeContractMasterCall(`${handelType}PongEstimateGas`, 0, chainId, ...params).then(pongFee =>
                        (isBNB ? createMemeContractSlaveCall('exactOutput', pongFee) : new Promise(resolve => resolve(pongFee))).then(pongFee => {
                            pongFee = bnbAmountMultiple(pongFee)
                            return createMemeContractSlaveCall(`${handelType}PingEstimateGas`, pongFee, masterChainId, ...params, claimFeeAmount).then(pingFee => Number(pingFee)).then(pingFee => {
                                // console.log('estimateGas', handelType, pongFee, pingFee);
                                return [pongFee, Math.ceil(npPlus(Number(pongFee), pingFee))]
                            })
                        })
                    ),
                estimateGas = checkData([data, 'estimateGas']),
                getDeadline = async () => BigInt(npPlus(Number((await slaveContractETH.getBlock()).timestamp), 30 * 60)),
                getDeposit = () => rC('deposited', address),
                setOmniDeposited = () => masterContractCall('omniDeposited', address).then(v => {
                    setData({omniDeposited: numberETH(v)})
                }),
                getBalance = async () => numberETH(await slaveContractETH.getBalance(address)),
                getTokenBalance = async () => {
                    const tokenBalanceWei = await rC('balanceOf', address)
                    currentCoinLongSymbol && setData({tokenBalanceWei})
                    return numberETH(tokenBalanceWei)
                },
                getAmountOutFunc = async (ethAmount, iSB = isSwapBuy, noBNB) => {
                    ethAmount = parseEtherWei(ethAmount)
                    const bnbETH = await bnbToETH(ethAmount)
                    iSB && isBNB && setData({bnbETH: numberETH(bnbETH)})
                    const {amount} = await masterContractCall('getAmountOut', iSB ? bnbETH : ethAmount, iSB)
                    return (isBNB && !iSB && !noBNB) ? (await ethToBNB(amount)) : amount
                },
                getAmountOut = async (...params) => numberETH(await getAmountOutFunc(...params)),
                getLocked = () => masterContractCall('locked', address).then(locked => {
                    const lockedFunc = index => numberETH(locked[index])
                    setData({
                        lockedETH: lockedFunc(0),
                        lockedToken: lockedFunc(1)
                    })
                }),
                eventDate = (timestamp, joinText = ' ', getYear, noSeconds) => {
                    const date = new Date(npTimes(Number(timestamp), 1000))
                    return [
                        date.toLocaleDateString('en-US', {month: 'short', day: 'numeric'}),
                        ...ternaryArray(getYear, [date.getFullYear()]),
                        ['getHours', 'getMinutes', ...ternaryArray(!noSeconds, ['getSeconds'])].map(v => {
                            v = date[v]()
                            return `${v < 10 ? 0 : ''}${v}`
                        }).join(':')
                    ].join(joinText)
                },
                isMyTrade = address ? checkData0(data, 'isMyTrade') : 0,
                getSwapEvent = noShowLoading => {
                    let isBlock10 = false, buySellEvent1 = []
                    const form = () => requestApi(
                        `${currentCoinLongSymbol ? 'short' : 'point'}/swap/log/form`, {
                            page: swapPage,
                            pageSize: 20,
                            token: masterContractAddress.toLocaleLowerCase(),
                            ...ternaryObject(
                                isMyTrade,
                                {address}
                            )
                        }
                    ).then(v => {
                            !noShowLoading && toggleShowLoading();
                            const items = checkDataArray(v, ...ternaryArray(isMyTrade && currentCoinLongSymbol, ['pageData']), 'items'),
                                items1 = [...items];
                            (swapPageIsFirst
                                ? Promise.all(items.map(async item => await Promise.all(
                                    buySellEvent1.map(v => item.txHash.toLocaleLowerCase() === v.transactionHash.toLocaleLowerCase() &&
                                        items1.splice(items1.indexOf(item), 1))
                                )))
                                : new Promise(resolve => resolve())).then(() => setData({
                                    buySellEvent: [
                                        ...ternaryArray(swapPage > 1 || isBlock10, isBlock10 ? buySellEvent1 : buySellEvent),
                                        ...items1.map(v => {
                                            const ethAmount = numberToFixed(v.eth),
                                                tokenAmount = numberToFixed(v.token),
                                                chainId1 = setTransactionChainId(checkData([v, 'srcChainId'])),
                                                {swapType} = v,
                                                earnEth = getFullNum(checkData0(v, 'earnEth'))
                                            return {
                                                ethAmount,
                                                tokenAmount,
                                                transactionHash: v.txHash,
                                                swapType,
                                                earnEth: earnEth == 0 ? '' : `${earnEth} ETH`,
                                                isBuy: ['BUY', 'GIVE'].includes(v.swapType),
                                                date: eventDate(Math.floor(v.logTime / 1000)),
                                                native: (() => {
                                                    const native = numberPointFixed(checkData0(v, 'amountNative'))
                                                    return native == 0 ? '' : `${native} ${contractDatas[chainId1]}`
                                                })(),
                                                price: tokenAmount == 0 ? 0 : npDivide(Number(ethAmount), Number(tokenAmount)),
                                                chainId: chainId1,
                                                shortPrice: getFullNum(checkData0(v, 'price')),
                                                longPrice: getFullNum(checkData0(v, 'longPrice'))
                                            }
                                        })
                                    ],
                                    ...(() => {
                                        const inOutVo = checkDataObject(v, 'inOutVo')
                                        return ternaryObject(
                                            isMyTrade,
                                            {totalCost: numberToFixed(npMinus(checkData0(inOutVo, 'outEth'), checkData0(inOutVo, 'inEth')))}
                                        )
                                    })()
                                }
                            ))
                        }
                    )
                    swapPageIsFirst
                        ? masterContractETH.getBlockNumber().then(v => lastBlock = Number(v)).then(
                            () => {
                                const step = 10
                                return masterContractETHGetEvents(currentCoinLongSymbol ? 'Trade' : 'Swap', 0, step).then(v =>
                                    masterContractETHGetEvents('MessageReceived', 0, step).then(
                                        messageReceivedList => {
                                            const messageReceivedChainIds = messageReceivedList.map(v =>
                                                    setTransactionChainId(checkData([v, 'returnValues', '_srcChainId']))),
                                                messageReceivedTransactionHashs = messageReceivedList.map(v => v.transactionHash)
                                            const list = []
                                            return Promise.all(v.map(async v => {
                                                const {transactionHash, blockHash, blockNumber} = v
                                                v = checkDataObject(v, 'returnValues')
                                                const isBuy = currentCoinLongSymbol ? ([1n, 3n].includes(v._action)) : Number(checkData0(v, 'amount0In')) > 0 || Number(checkData0(v, 'amount1Out')) > 0,
                                                    ethAmount = numberETH(currentCoinLongSymbol ? checkData0(v, '_native') : checkData0(v, isBuy ? 'amount0In' : 'amount0Out')),
                                                    tokenAmount = numberETH(currentCoinLongSymbol ? checkData0(v, '_token') : checkData0(v, isBuy ? 'amount1Out' : 'amount1In'))
                                                // list.push({
                                                //     isBuy,
                                                //     ethAmount,
                                                //     tokenAmount,
                                                //     blockNumber,
                                                //     transactionHash,
                                                //     price: getFullNum(npDivide(Number(ethAmount), Number(tokenAmount)), true),
                                                //     date: eventDate((await masterContractETH.getBlock(blockHash)).timestamp),
                                                //     chainId: messageReceivedTransactionHashs.includes(transactionHash)
                                                //         ? checkData([
                                                //             messageReceivedChainIds,
                                                //             messageReceivedTransactionHashs.indexOf(transactionHash)
                                                //         ], masterChainId)
                                                //         : masterChainId
                                                // })
                                            })).then(() => {
                                                    isBlock10 = true;
                                                    !noShowLoading && toggleShowLoading();
                                                    buySellEvent1 = list.sort((a, b) => Number(b.blockNumber) - Number(a.blockNumber))
                                                }
                                            ).then(() => form()).catch(() => !noShowLoading && toggleShowLoading())
                                        }
                                    )
                                )
                            }
                        ).catch(() => !noShowLoading && toggleShowLoading())
                        : form()
                },
                reloadForm = noShowLoading => {
                    !noShowLoading && toggleShowLoading(true);
                    if (swapPageIsFirst) {
                        getSwapEvent(noShowLoading)
                    } else {
                        setFistSwapPage()
                    }
                },
                getTask = () => requestApi('point/task', {address}),
                getInitialPrice = () => currentCoinLongSymbol
                    ? new Promise(reserves => reserves(checkData0(currentCoinInitialPrice)))
                    : masterContractCall('presaleNative').then(presaleNative =>
                        masterContractCall('presaleSupply').then(presaleSupply =>
                            npDivide(Number(presaleNative), Number(presaleSupply))
                        )),
                // contractCreateTimestamp = () => masterContractETH.getBlock(createBlockNumber).then(v => v.timestamp),
                setChart = () => blockTimestamp().then(
                    async timestamp => {
                        setData({chartData: [], minEthPrice: 0, chartDataIndex: undefined})
                        const timeSlot = timeSlots[timeSlotIndex],
                            duration = timeSlot[1],
                            durationIsNotNull = isNotNull(duration)
                        let cCT = currentCoinInitialDate
                        cCT = isNotNull(cCT)
                            ? cCT
                            : npMinus(timestamp, 31536000000)
                        requestApi(
                            `${currentCoinLongSymbol ? 'short' : 'point'}/swap/log/kline`, {
                                endTime: timestamp,
                                count: timeSlot[2],
                                token: masterContractAddress.toLocaleLowerCase(),
                                startTime: durationIsNotNull
                                    ? (() => {
                                        const d = npTimes(86400000, duration)
                                        return d < npMinus(timestamp, cCT)
                                            ? Math.floor(npMinus(timestamp, d))
                                            : cCT
                                    })()
                                    : cCT,
                            }
                        ).then(async v => setData({
                            chartData: await (async () => {
                                let mEP = 0
                                return [
                                    ...ternaryArray(
                                        !durationIsNotNull,
                                        [{
                                            price: await getInitialPrice(),
                                            date: cCT
                                        }]
                                    ),
                                    ...checkDataArray(v).map(v => ({
                                        price: v.ethPrice,
                                        date: v.logTime
                                    })),
                                    {
                                        price: formatEther(await getAmountOutFunc(1, false, true)),
                                        date: timestamp
                                    }
                                ].map(v => {
                                    const {price} = v
                                    if (price > 0 && mEP === 0) {
                                        mEP = price
                                        setData({minEthPrice: price})
                                    }
                                    return {
                                        price,//: getFullNum(price).toString(),
                                        date: Math.floor(v.date / 1000)
                                    }
                                })
                            })()
                        }))
                    }
                ),
                maxSwapRatio = 1,
                // maxSwapRatio = 0.05,
                // getReserves = () => masterContractCall('getReserves').then(async reserves =>
                //     setData({reserves: reserves.map(v => numberETH(v))})),
                // const maxAmountFunc = index => npTimes(numberETH(checkData([reserves, index])), maxSwapRatio),
                //     // const maxAmountFunc = index => numberETH(npTimes(Number(checkData([reserves, index])), maxSwapRatio)),
                //     // totalSwapAmountFunc = (index, iSR) => getAmountOut(numberETH(checkData([reserves, index])), iSR),
                //     // totalSwapETHAmount = await totalSwapAmountFunc(1, false),
                //     // parseEtherWeiTotalSwapETHAmount = parseEtherWei(totalSwapETHAmount),
                //     maxETHAmount = maxAmountFunc(0)
                // getEstimateGas(undefined,true,'buy', parseEtherWei(maxETHAmount)).then(([pongFee, estimateGas]) =>
                //     setData({
                //         // maxETHAmount,
                //         // maxTokenAmount: maxAmountFunc(1),
                //         // maxETHAmountEstimateGas: numberToFixed(npPlus(maxETHAmount, numberFormatEther(estimateGas))),
                //         // totalSwapETHAmountNoEstimateGas: numberETH(parseEtherWeiTotalSwapETHAmount),
                //         // totalSwapTokenAmount: totalSwapAmountFunc(0, true),
                //         // totalSwapETHAmountNoEstimateGas: numberETH(parseEtherWeiTotalSwapETHAmount),
                //         // totalSwapETHAmount: numberETH(npPlus(Number(parseEtherWeiTotalSwapETHAmount), estimateGas)),
                //     })
                // )
                // }),
                getReserves = () => masterContractCall('getReserves'),
                getDepositTotal = () => rC('depositTotal', address),
                setOmniChain = () => (isNewMemeNoUTKOrPUPPET
                    ? getReserves().then(reserves => reserves[0])
                    : masterContractCall('presaleAccumulate')).then(omniChain => (isNewMeme
                    ? masterContractCall('launchHardCap')
                    : new Promise(resolve => resolve())).then(launchHardCap =>
                    setData({omniChain, launchHardCap}))),
                getRoundMemeList = () => {
                    toggleShowLoading(true)
                    let arr = []
                    const step = 100,
                        getFunc = (page = 0) => masterContractCall(
                            'getRoundMemeList',
                            npPlus(npTimes(page, step), 1), npTimes(npPlus(page, 1), step)
                        ).then(
                            currentRoundMemeList => {
                                // console.log(currentRoundMemeList, '+++++++++++++++++++++++++++++');
                                arr = [...arr, ...currentRoundMemeList]
                                if (currentRoundMemeList.length < step) {
                                    arr = arr.map(v => memeData(v)).filter(v => !checkDataString(v, 'symbol').includes('LIKTEST')).sort((a, b) => Number(b.votes) - Number(a.votes))
                                    setData({
                                        showLoading: false,
                                        hotMemes: arr.slice(0, 6),
                                        currentRoundMemeList: arr.map(
                                            (v, i) => ({...v, ranking: i})
                                        ).sort(() => Math.random() - 0.5)
                                    })
                                } else {
                                    getFunc(page + 1)
                                }
                            }
                        )
                    getFunc()
                },
                urlState = checkDataString(new URLSearchParams(location.search).get('state')).split('_'),
                voteSymbol = checkData([pNs1], checkDataString(urlState, 1)).toLocaleUpperCase(),
                isVoteDetail = isVotePage && isNotNullOrEmpty(voteSymbol),
                getRoundMeme = symbol => {
                    const symbolIsTure = rM => rM.symbol.toLocaleUpperCase() === symbol
                    return masterContractCall('roundMemesIndex', symbol).then(
                        roundMemeIndex => masterContractCall('roundMemes', roundMemeIndex).then(
                            roundMeme => {
                                if (symbolIsTure(roundMeme)) {
                                    setData({voteMemeNotInRound: false})
                                    return memeData(roundMeme)
                                } else {
                                    return masterContractCall('memesIndex', symbol).then(
                                        roundMemeIndex => masterContractCall('memes', roundMemeIndex).then(
                                            roundMeme => {
                                                if (symbolIsTure(roundMeme)) {
                                                    setData({voteMemeNotInRound: true})
                                                    return memeData(roundMeme)
                                                } else {
                                                    return undefined
                                                }
                                            }
                                        )
                                    )
                                }
                            }
                        )
                    )
                },
                getVoteMeme = () => getRoundMeme(voteSymbol).then(roundMeme => setData({voteMeme: roundMeme})),
                getAllVotePoints = () => masterContractCall('allVotePoints', voteSymbol).then(allVotePoints =>
                    setData({allVotePoints: Number(allVotePoints)})),
                getInvites = address => masterContractCall('invitesLength', voteSymbol, address).then(invitesLength =>
                    invitesLength <= 0
                        ? []
                        : masterContractCall('getInvites', voteSymbol, address, 1, invitesLength)),
                getVotePoints = () => masterContractCall('votePoints', voteSymbol, address).then(votePoints => setData({votePoints: Number(votePoints)})),
                getVotePointsChildrenVoted = () => {
                    getInvites(address).then(inviteRecords => setData({inviteRecords}))
                    getVotePoints()
                    masterContractCall('voted', address, voteSymbol).then(isVoted => setData({isVoted}))
                },
                isVoteSearch = checkDataBool(data, 'isVoteSearch'),
                votedMemeSymbols = checkDataArray(data, 'votedMemeSymbols'),
                isVotedMeme = symbol => votedMemeSymbols.includes(symbol),
                searchItemList = checkDataArray(data, 'searchItemList'),
                currentRoundMemeList = checkDataArray(data, 'currentRoundMemeList'),
                lastListingMeme = checkDataObject(coins, 0),
                searchCurrentRoundMeme = closeSearch => {
                    if (!closeSearch && searchSymbolIsNotNull) {
                        setData({isVoteSearch: true})
                        toggleShowLoading(true)
                        const arr = []
                        Promise.all(currentRoundMemeList.map((v, i) => v.symbol.toLocaleUpperCase().includes(searchSymbol.toLocaleUpperCase()) && arr.push({
                            ...v,
                            ranking: checkData([v, 'ranking'], i)
                        }))).then(
                            () => {
                                setData({searchItemList: arr})
                                toggleShowLoading()
                            }
                        )
                    } else {
                        setData({isVoteSearch: false})
                    }
                },
                canAccessWalletConnectUrl = (
                    func = () => {
                    },
                    falseFunc = () => {
                    }
                ) => {
                    func()
                    // const walletConnectUrl = 'https://walletconnect.com'
                    // axios(
                    //     {
                    //         timeout: 5000,
                    //         url: walletConnectUrl,
                    //     },
                    // ).then(() => func())
                    //     .catch(() => {
                    //         falseFunc()
                    //         toast(`Make sure your network can access to ${walletConnectUrl},Refresh and try again`)
                    //     })
                },
                openWeb3Modal = (...params) => {
                    if (addressIsNotNull) {
                        open(...params)
                    } else {
                        const toggleShowConnectLoading = sCL => setData({showConnectLoading: sCL})
                        toggleShowConnectLoading(true)
                        canAccessWalletConnectUrl(() => {
                            toggleShowConnectLoading()
                            open(...params)
                        }, () => toggleShowConnectLoading())
                    }
                },
                selectNetworkObj = {view: 'Networks'},
                connect = () => isOpen ? close() : openWeb3Modal(address ? undefined : selectNetworkObj),
                selectNetwork = () => isOpen ? close() : openWeb3Modal(selectNetworkObj),
                switchEthereumChain = (
                    func = () => {
                    },
                    falseFunc = () => {
                    },
                    needConnect,
                    chain = {}
                ) => {
                    const pleaseConnect = () => {
                        const {name} = chain
                        toast(isNotNullOrEmpty(name) ? `Please connect ${name}` : 'Please connect to the network')
                        setTimeout(() => openWeb3Modal(selectNetworkObj), 1000)
                    }, {id: chainId1} = chain, chainId1IsNotNull = isNotNull(chainId1)
                    if (addressIsNotNull) {
                        if (
                            (!chainId1IsNotNull || (chainId1IsNotNull && chainId === chainId1)) &&
                            !wrongNetwork
                        ) {
                            func()
                        } else {
                            falseFunc()
                            pleaseConnect()
                        }
                    } else {
                        falseFunc()
                        if (needConnect) {
                            pleaseConnect()
                        }
                    }
                },
                getPoints = (v, key, point = true) => parseInt(checkData0(v, key, ...ternaryArray(point, ['point']))),
                authorizeData = checkData([data, 'authorizeData'], twitterAuthorizeData),
                {authorizeKey} = authorizeData,
                bindCheck = (aK = authorizeKey) => requestApi(`${aK}/bind/check`),
                toggleShowAuthorize = (showAuthorize, isCloudflare, aD = authorizeData) => showAuthorize
                    ? switchEthereumChain(
                        () => {
                            toggleShowLoading(true)
                            const aK = aD.authorizeKey
                            loginThenFunc(() => bindCheck(aK).then(isBinded => setData({
                                ...ternaryObject(
                                    isTwitterAuthorizeAuthorize(aK),
                                    {isTwitterBinded: isBinded}
                                ),
                                isCloudflare,
                                showAuthorize,
                                authorizeData: aD,
                                showLoading: false,
                            })), toggleShowLoading)
                        },
                        undefined,
                        true
                    )
                    : setData({showAuthorize}),
                newAmount = checkDataString(data, 'newAmount'),
                newAmountNumber = Number(newAmount),
                newAmountNumberGT0 = newAmountNumber > 0,
                parseEtherWeiNewAmount = parseEtherWei(newAmount),
                amountReplace = (nSP = 0) => nSP.toString().replace(/[^\d.]/g, '').replace(/\.{2,}/g, ".")
                    .replace(/^\./g, "").replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
                    .replace(/^(\-)*(\d+)\.(\d{9}).*$/, '$1$2.$3'),
                setNewAmount = (nSP = 0, key = 'newAmount') => {
                    const num = amountReplace(nSP)
                    setData({
                        [key]: (num > 0 && num < minBigInt) ? 0 : num
                    })
                },
                setNewAmountEmpty = () => setData({newAmount: ''}),
                setMaxETH = (funcName, handelType, paramsFunc = () => [], noGas, otherPingParams) =>
                    Number(ethBalance) > 0
                        ? (ethBalance < maxETHBalance
                            ? getEstimateGas(
                                otherPingParams, true,
                                checkData([handelType], funcName), parseEtherWeiEthBalance).then(([pongFee, estimateGas] = []) =>
                                (noGas ? new Promise(resolve => resolve(0)) : slaveContractETH.estimateGas({
                                    from: address,
                                    to: slaveContractAddress,
                                    value: parseEtherWeiEthBalance,
                                    data: (new slaveContractETH.Contract(slaveContractAbi, slaveContractAddress)).methods[funcName](pongFee, ...paramsFunc(estimateGas)).encodeABI(),
                                })).then(gas =>
                                    slaveContractETH.getGasPrice().then(gasPrice => {
                                        const value = npMinus(Number(sendETHBalance), npPlus(
                                            numberFormatEther(estimateGas),
                                            npTimes(numberFormatEther(npTimes(Number(gas), Number(gasPrice))), gasMultiple)))
                                        setNewAmount(value > 0 ? value : 0)
                                    })
                                ).catch(e => toast(checkData([e, 'innerError', 'message'], e.message))))
                            : setNewAmount(maxETHBalance))
                        : setNewAmount(0),
                // setMaxETH=(estimateGasFuncName,estimateGasParams=[])=>getEstimateGas(undefined,true,estimateGasFuncName, address, ).then((estimateGas = []) => {
                //     const value = parseEtherWei(newAmount)
                //     slaveContractETH.estimateGas({
                //         from: address,
                //         to: slaveContractAddress,
                //         value: Number(value),
                //         data: (new slaveContractETH.Contract(slaveContractAbi, slaveContractAddress)).methods.deposit(value).encodeABI(),
                //     }).then(gas =>
                //         slaveContractETH.getGasPrice().then(gasPrice => {
                //             const value = npMinus(Number(ethBalance), npPlus(numberFormatEther(estimateGas), npTimes(numberFormatEther(npTimes(gas, gasPrice)), 1.5)))
                //             setNewAmount(value > 0 ? value : 0)
                //         })
                //     ).catch(e => toast(checkData([e, 'innerError', 'message'], e.message)))
                // }),
                sMC = currentCoinShortSymbol ? shortMasterCall : masterContractCall,
                sMCCondition = currentCoinShortSymbol || currentCoinLongSymbol,
                shortMasterLaunchedLiquidated = () => sMC('launched').then(
                    shortMasterLaunched => sMC('liquidated').then(
                        liquidated => [shortMasterLaunched, liquidated])).catch(() => []),
                swapStatusEndReload = () => {
                    getReserves().then(async reserves => setData({reserves: [0, 1].map(v => numberETH(reserves[v]))}))
                    if (sMCCondition) {
                        sMC('initLongPrice').then(
                            initLongPrice => (currentCoinLongSymbol ? longMasterCall : masterContractCall)('getCurrentPrice').then(
                                longPrice => shortMasterLaunchedLiquidated().then(
                                    ([shortMasterLaunched, liquidated]) => {
                                        longPrice = numberETH(longPrice)
                                        setData({
                                            longPrice,
                                            liquidated,
                                            shortMasterLaunched,
                                            initLongPrice: initLongPrice === 0n ? longPrice : numberETH(initLongPrice),
                                        })
                                    })));
                    }
                },
                setContractStatuData = (tx, contractAddress, functionName, hasPong, txObj = {}, remove) => {
                    let cSD = {...contractStatuData()}
                    const cSDC = checkDataObject(cSD, chainId)
                    let cSDCA = checkDataObject(cSDC, address)
                    const cSDCATX = checkDataObject(cSDCA, tx)
                    if (remove) {
                        delete cSDCA[tx]
                        if (isSwap && masterContractAddress && slaveContractAddress && address && buySellFunctionNames.includes(checkData([cSDCATX, 'functionName']))) {
                            setChart()
                            swapStatusEndReload()
                            reloadForm(true)
                            getBalance().then(ethBalance => getTokenBalance().then(tokenBalance => setData({
                                ethBalance,
                                tokenBalance
                            })))
                        }
                    }
                    cSD[chainId] = {
                        ...cSDC,
                        [address]: {
                            ...cSDCA,
                            ...ternaryObject(
                                !remove,
                                {
                                    [tx]: {
                                        ...cSDCATX,
                                        ...ternaryObject(
                                            !isNotNull(cSDCATX.lastTime),
                                            {lastTime: npPlus(Date.now(), 10800000)}
                                        ),
                                        ...ternaryObject(
                                            isNotNullOrEmpty(contractAddress),
                                            {contractAddress}
                                        ),
                                        ...ternaryObject(
                                            isNotNullOrEmpty(functionName),
                                            {functionName}
                                        ),
                                        ...ternaryObject(
                                            !hasPong,
                                            {
                                                ping: {
                                                    status: 2,
                                                    opStatus: 99,
                                                    targetId: tx,
                                                }
                                            }
                                        ),
                                        ...txObj
                                    }
                                }
                            )
                        }
                    }
                    cSD = JSON.stringify(cSD)
                    localStorage.setItem('contractStatuData', cSD)
                    dispatch(props, {contractStatuData: cSD})
                },
                wC = (contractAddress, contractAbi, functionName, value, args = [], successFunc = () => {
                }, failFunc = () => {
                }) => {
                    dispatch(props, {contractStatuData: localStorage.getItem('contractStatuData') || '{}'})
                    const toggleShowLoadingToast = text => {
                            toggleShowLoading()
                            text && toast(text)
                        },
                        wCFunc = () => {
                            toggleShowLoading(true)
                            prepareWriteContract({
                                args,
                                value,
                                functionName,
                                abi: contractAbi,
                                address: contractAddress,
                                walletClient: {account: address},
                            }).then(r => writeContract(r.request).then(r => {
                                const hash = checkData([r, 'hash'])
                                if (hash && [...pingFeeFunctionName, ...pongFeeFunctionName].includes(functionName)) {
                                    setContractStatuData(hash, contractAddress, functionName, pongFeeFunctionName.includes(functionName))
                                }
                                return successFunc ? successFunc(hash) : toggleShowLoadingToast(hash ? 'Successful' : 'Failed')
                            })
                                .catch(e => toggleShowLoadingToast(e.message.split('\n')[0])))
                                .catch(e => {
                                    const errorStr = [1, 2].map(v => e.message.split('\n')[v]).join('\n'),
                                        obj = {}
                                    let eStr
                                    Object.keys(obj).map(v => {
                                        if (errorStr.includes(v)) eStr = obj[v]
                                    })
                                    toggleShowLoadingToast(eStr || errorStr)
                                    checkDataFunction(failFunc)()
                                })
                        }
                    switchEthereumChain(
                        () => {
                            if (Object.values(checkDataObject(contractStatuData(), chainId, address)).map(v => v.functionName).includes(functionName)) {
                                setData({showPreviousTips: true, previousTipsFunc: wCFunc})
                            } else {
                                wCFunc()
                            }
                        },
                        toggleShowLoadingToast,
                        true
                    )
                },
                slaveWriteContract = (...params) => wC(slaveContractAddress, slaveContractAbi, ...params),
                createMemeMasterWriteContract = (...params) => wC(createMemeContractMasterAddress, createMemeContractMasterAbi, ...params),
                createMemeSlaveWriteContract = (...params) => wC(createMemeContractSlaveAddress, createMemeContractSlaveAbi, ...params),
                contestContractWriteContract = (...params) => wC(contestContractAddress, contestContractAbi, ...params),
                pageFootPhone = <div className={'paddingPhone childrenSpacingTopPhone'}>
                    <div className={'flexCenter justifyContentSpaceBetween'}>
                        <div onClick={devCountAdd}
                             className={'textAlignCenter smallFontSizePhone'}
                        >
                            © All rights reserved, 2024
                        </div>
                        <div className={'flex childrenSpacingLeftPhone'}>
                            {
                                socialLinks.map(v =>
                                    <img src={requireDefault(`${v[0]}3`)} alt="" className={'cursorPointer'}
                                         onClick={() => windowOpen(v[1])}
                                         style={{width: '2rem'}}/>)
                            }
                        </div>
                    </div>
                </div>,
                totalPoints = checkData0(data, 'totalPoints'),
                showTaskContent = checkDataBool(data, 'showTaskContent'),
                toggleShowTaskContent = (showTaskContent, taskContentText) => setData({showTaskContent, ...ternaryObject(showTaskContent, {taskContentText})}),
                taskContent = showTaskContent &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.56)',
                         }}>
                        <div
                            className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone borderRadiusPhone' : 'childrenSpacingTopBigger'} boxSizingBorderBox`}
                            style={{
                                width: isPhoneClientWidth ? '84%' : '10rem',
                                maxHeight: '100vh',
                                ...ternaryObject(
                                    !isPhoneClientWidth,
                                    {borderRadius: '.5rem'}
                                ),
                                padding: `${isPhoneClientWidth ? 1.5 : .7}rem`,
                                border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                            }}>
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div className={'saf'}
                                     style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                    INFORMATION
                                </div>
                                <img onClick={() => toggleShowTaskContent()}
                                     className={'cursorPointer'}
                                     src={requireDefault('close')}
                                     alt="" style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                            </div>
                            <pre style={{lineHeight: 2}}
                                 className={isPhoneClientWidth ? 'smallerFontSizePhone' : ''}>{checkDataString(data, 'taskContentText')}</pre>
                        </div>
                    </div>,
                redirectURI = `https://likwid.meme/${pNs0}`,
                isTwitterBinded = checkDataBool(data, 'isTwitterBinded'),
                isTwitterAuthorized = checkData0(data, 'twitterAuthorizePoints') > 0,
                isTGAuthorized = checkData0(data, 'tGAuthorizePoints') > 0,
                twitterCode = checkData([new URLSearchParams(location.search).get('code')]),
                showAuthorize = checkDataBool(data, 'showAuthorize'),
                cloudflareHumanDiv = <div
                    className={'childrenDisplayBlock flexCenter'}
                    style={{
                        transform: `scale(${document.body.clientWidth / (isPhoneClientWidth ? 600 : 1500)})`,
                    }}
                    id={'cloudflareHuman'}/>,
                {authorizePoints} = authorizeData,
                verify = () => requestApi(
                    `${authorizeKey}/verify`,
                    undefined,
                    'post'
                ).then(r => {
                    toggleShowLoading()
                    if (isNotNull(r)) {
                        toastSuccessful(true)
                        getTask().then(v => setData({
                            tGAuthorizePoints: getPoints(v, 5),
                            twitterAuthorizePoints: getPoints(v, 4),
                            totalPoints: getPoints(v, -1, false),
                        }))
                        toggleShowAuthorize()
                    } else if (authorizeKey === tGAuthorizeAuthorizeKey) {
                        toast('Send my code on LIKWID Community')
                        setData({tGShareDone: false})
                    }
                }),
                authorizeContent = showAuthorize &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.56)',
                         }}>
                        <div
                            className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone borderRadiusPhone' : 'childrenSpacingTopBigger'} boxSizingBorderBox`}
                            style={{
                                width: isPhoneClientWidth ? '84%' : '8.5rem',
                                maxHeight: '100vh',
                                ...ternaryObject(
                                    !isPhoneClientWidth,
                                    {borderRadius: '.5rem'}
                                ),
                                padding: `${isPhoneClientWidth ? 1.5 : .7}rem`,
                                border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                            }}>
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div className={'saf'}
                                     style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                    {authorizeData.title}
                                </div>
                                <img onClick={() => toggleShowAuthorize()}
                                     className={'cursorPointer'}
                                     src={requireDefault('close')}
                                     alt="" style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                            </div>
                            <div
                                className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBigger'}>
                                <div
                                    className={`${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} flexCenter`}>
                                    <img src={requireDefault('integral')} alt=""
                                         style={{width: `${isPhoneClientWidth ? 2.7 : .9}rem`}}/>
                                    <div
                                        className={`saf1 ${isPhoneClientWidth ? 'biggerFontSizePhone' : 'biggerFontSize'}`}>
                                        {authorizePoints}
                                    </div>
                                </div>
                                {
                                    (isTwitterBinded || !authorizeData.needBind)
                                        ? <div
                                            className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTop'}>
                                            {
                                                {
                                                    x: () => {
                                                        const twitterFollowDone = checkDataBool(data, 'twitterFollowDone'),
                                                            twitterShareDone = checkDataBool(data, 'twitterShareDone')
                                                        return [
                                                            ['Follow @likwid_fi on X', 'Follow',
                                                                () => {
                                                                    setData({twitterFollowDone: true})
                                                                    windowOpen('https://x.com/intent/user?screen_name=likwid_fi')
                                                                }, true, twitterFollowDone],
                                                            ['Share likwid platform on X', 'Share',
                                                                () => {
                                                                    setData({twitterShareDone: true})
                                                                    windowOpen(`https://x.com/share?text=${encodeURIComponent(`🎉✨"Excited to join @likwid_fi with 9 L2s #Arbitrum #Optimism #Base #Linea #Scroll #Blast #Bob #Polygon #Vizing - the ultimate boutique meme creator and trading platform! Pump up the fun and start earning rewards today! 🚀💰 

likwid.meme

#LIKWID #Droplets #meme`)}`)
                                                                }, twitterFollowDone, twitterShareDone],
                                                            [`Get ${twitterAuthorizePoints} droplets`, 'Verify', () => {
                                                                toggleShowLoading(true)
                                                                verify()
                                                            }, twitterFollowDone && twitterShareDone],
                                                        ]
                                                    },
                                                    tg: () => {
                                                        const tGFollowDone = checkDataBool(data, 'tGFollowDone'),
                                                            tGShareDone = checkDataBool(data, 'tGShareDone')
                                                        return [
                                                            ['Join the LIKWID Community', 'Join',
                                                                () => {
                                                                    setData({tGFollowDone: true})
                                                                    windowOpen(likwidMemeTG)
                                                                }, true, tGFollowDone],
                                                            ['Send my code on LIKWID Community', 'Send',
                                                                () => {
                                                                    setData({tGShareDone: true})
                                                                    requestApi('tg/code', {address}).then(code =>
                                                                        (isPhoneClientWidth ? windowReplace : windowOpen)(`https://telegram.me/share/url?url=%2fcode%20likwid_${code}`))
                                                                }, tGFollowDone, tGShareDone],
                                                            [`Get ${tGAuthorizePoints} droplets`, 'Verify', () => {
                                                                toggleShowLoading(true)
                                                                verify()
                                                            }, tGFollowDone && tGShareDone],
                                                        ]
                                                    }
                                                }[authorizeKey]().map(v => {
                                                    const isTrue = v[3], isDone = v[4]
                                                    return <div className={'flexCenter justifyContentSpaceBetween'}>
                                                        <div
                                                            className={isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'}>{v[0]}</div>
                                                        <div style={{
                                                            padding: `${isPhoneClientWidth ? .2 : .1}rem 0`,
                                                            width: `${isPhoneClientWidth ? 4 : 1.3}rem`,
                                                            ...ternaryObject(
                                                                isDone,
                                                                {background: '#FF7789'}
                                                            )
                                                        }}
                                                             onClick={() => isTrue && !isDone && v[2]()}
                                                             className={`${isTrue ? 'backgroundBlack' : 'backgroundWhite'} flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'} borderRadius999 cursorPointer`}>
                                                            <div
                                                                className={`${isTrue ? 'colorWhite' : 'colorGray'} ${isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'}`}>{isDone ? 'Done' : v[1]}</div>
                                                            {isDone &&
                                                                <img src={requireDefault('Ellipse 45')} alt=""
                                                                     style={{width: `${isPhoneClientWidth ? .7 : .25}rem`}}
                                                                />}
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        : [
                                            <div
                                                className={`textAlignCenter ${isPhoneClientWidth ? 'smallFontSizePhone' : ''}`}>
                                                Complete {authorizeKey.toLocaleUpperCase()} authorization before starting the
                                                task
                                            </div>,
                                            checkDataBool(data, 'isCloudflare')
                                                ? cloudflareHumanDiv
                                                : <div
                                                    onClick={() => {
                                                        const code_challenge = require('uuid').v4().slice(0, 10)
                                                        windowReplace(
                                                            'https://twitter.com/i/oauth2/authorize?response_type=code' +
                                                            '&client_id=MUpkUS14Y3VFaWNGV1hFQnpoNUk6MTpjaQ' +
                                                            `&redirect_uri=${redirectURI}` +
                                                            '&scope=offline.access%20tweet.read%20users.read' +
                                                            `&state=${[
                                                                code_challenge,
                                                                ...ternaryArray(isVotePage, [voteSymbol])
                                                            ].join('_')}` +
                                                            `&code_challenge=${code_challenge}` +
                                                            '&code_challenge_method=plain'
                                                        )
                                                    }}
                                                    className={`borderRadius999 saf backgroundBlack activeColor flexCenter cursorPointer width100 ${isPhoneClientWidth ? 'childrenSpacingLeftBigPhone' : 'childrenSpacingLeftBig'}`}
                                                    style={{
                                                        padding: `${isPhoneClientWidth ? .75 : .25}rem 0`,
                                                        marginTop: `${isPhoneClientWidth ? 1.1 : .28}rem`
                                                    }}>
                                                    Authorize
                                                </div>
                                        ]
                                }
                            </div>
                        </div>
                    </div>,
                launchAPP = () => toast('Sepolia will launch in December. Participate to receive an airdrop of leveraged positions.'),
                isTop = checkData([data, 'isTop'], true),
                isTopColor = `isTop${isTop ? 'Transparent' : 'White'}`,
                pageTopPhone = <div className={`positionFixed zIndex999 ${isTopColor}`} style={{left: 0, right: 0}}>
                    <div
                        className={'width100 boxSizingBorderBox flex justifyContentSpaceBetween alignItemsCenter paddingLeftRightBigPhone'}
                        style={{height: '3rem'}}>
                        <img onClick={replaceHome} src={requireDefault('logo')}
                             style={{height: '1.5rem'}} alt=""/>
                        <div onClick={launchAPP}
                             className={'borderRadius999 saf1 flexCenter cursorPointer activeColor smallFontSizePhone'}
                             style={{
                                 background: '#FFF4F5',
                                 padding: '0 .7rem',
                                 height: '1.7rem',
                             }}>
                            Launch APP
                        </div>
                    </div>
                </div>,
                pageTopFootPhone = (content, showEnd = true) => <div>
                    {pageTopPhone}
                    {content}
                    {showEnd && pageFootPhone}
                    {connectLoading(10)}
                </div>,
                pageTop = [
                    <div
                        className={`ttf positionFixed flex alignItemsCenter justifyContentSpaceBetween zIndex999 ${isTopColor}`}
                        style={{
                            left: 0,
                            right: 0,
                            height: '1.2rem',
                            padding: '0 2rem'
                        }}>
                        {(() => {
                            return [
                                <img onClick={replaceHome} src={requireDefault('logo')}
                                     style={{height}} alt="" className={'cursorPointer'}/>,
                                <div onClick={launchAPP}
                                     className={'borderRadius999 saf1 flexCenter cursorPointer activeColor'}
                                     style={{
                                         background: '#FFF4F5',
                                         padding: '0 .3rem',
                                         height: '.7rem',
                                     }}>
                                    Launch APP
                                </div>,
                            ]
                        })()}
                        <div style={{top: '1.5rem', left: 0, right: 0, height: 0}}
                             className={'positionAbsolute width100 flexColumn alignItemsFlexEnd boxSizingBorderBox paddingRight'}>
                            {contractStatuList}
                        </div>
                    </div>,
                    connectLoading(5)
                ],
                pageFoot = <div style={{height: '1rem', padding: '0 2rem'}}
                                className={'width100 flexCenter justifyContentSpaceBetween boxSizingBorderBox'}
                >
                    <div onClick={devCountAdd} className={'saf2'}>
                        © All rights reserved, 2024
                    </div>
                    <div className={'flex childrenSpacingLeft'}>
                        {
                            socialLinks.map(v => <img src={requireDefault(`${v[0]}3`)} alt=""
                                                      onClick={() => windowOpen(v[1])}
                                                      className={'cursorPointer'}
                                                      style={{width: '.5rem', height: '.5rem'}}/>)
                        }
                    </div>
                </div>,
                searchListedMeme = (closeSearch, rGLML = reGetListedMemeList) => {
                    if (!closeSearch && searchSymbolIsNotNull) {
                        setData({isLaunchSearch: true})
                        toggleShowLoading(true)
                        rGLML().catch(() => {
                            setData({coins: undefined})
                            toggleShowLoading()
                        })
                    } else {
                        isLaunchSearch && setDataSearchSymbolEmpty()
                    }
                },
                showSelectCoins = checkDataBool(data, 'showSelectCoins'),
                toggleShowSelectCoins = showSelectCoins => {
                    setData({showSelectCoins})
                    if (showSelectCoins) {
                        setDataSearchSymbolEmpty()
                        searchListedMeme(true)
                    }
                },
                selectFunc = (isToggleFunc, toggleFunc, showValue, currentIcon, currentName, list, isCurrentFunc, setIndexFunc, logoFunc, nameFunc, isChains, shortToken) =>
                    <div onClick={() => isToggleFunc && toggleFunc(!showValue)}
                         className={`${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeft'} flexCenter positionRelative ${isToggleFunc ? 'cursorPointer' : ''}`}>
                        <div className={`positionRelative ${isChains ? '' : 'borderRadius999'}`}
                             style={square(`${isChains ? (isPhoneClientWidth ? .9 : .3) : (isPhoneClientWidth ? 1.5 : .7)}rem`)}>
                            <img className={'width100 height100'} src={currentIcon}
                                 alt=""/>
                            {shortToken && currentCoinLongSymbol &&
                                <img className={'width100 height100 positionAbsolute'} style={{top: 0, left: 0}}
                                     src={requireDefault('shortToken')} alt=""/>}
                        </div>
                        <div className={isChains ? 'saf' : 'saf1'}
                             style={{fontSize: `${isPhoneClientWidth ? .9 : .3}rem`}}>{currentName}</div>
                        {
                            isToggleFunc && [
                                <img
                                    src={requireDefault((isChains && showValue) ? 'toTop' : 'toBottom')}
                                    alt=""
                                    style={{width: `${isPhoneClientWidth ? .9 : .3}rem`}}/>,
                                ...ternaryArray(
                                    isChains && showValue,
                                    [
                                        <div
                                            className={`${isPhoneClientWidth ? 'borderRadiusPhone paddingPhone' : 'borderRadius padding'} backgroundActiveColor positionAbsolute zIndex1`}
                                            style={{
                                                left: 0,
                                                marginLeft: 0,
                                                border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                                top: `${isChains ? (isPhoneClientWidth ? 2.5 : .75) : (isPhoneClientWidth ? 2.45 : 1)}rem`,
                                                width: `${isChains ? (isPhoneClientWidth ? 10.5 : 3.5) : (isPhoneClientWidth ? 8 : 3)}rem`,
                                            }}
                                        >
                                            {
                                                list.map((v, i) => <div onClick={() => setIndexFunc(v, i)}
                                                                        className={`${isPhoneClientWidth ? 'paddingPhone childrenSpacingLeftSmallPhone' : 'padding childrenSpacingLeftSmall'} flex cursorPointer alignItemsCenter  borderRadius999 
                                                                                            ${isCurrentFunc(v, i) ? 'backgroundWhite' : ''}`}>
                                                    <img
                                                        style={{width: `${isPhoneClientWidth ? .9 : .3}rem`}}
                                                        src={logoFunc(v, i)}
                                                        alt=""/>
                                                    <div
                                                        className={'saf1'}
                                                        style={{fontSize: `${isPhoneClientWidth ? .55 : .2}rem`}}
                                                    >
                                                        {nameFunc(v, i)}
                                                    </div>
                                                </div>)
                                            }
                                        </div>
                                    ]
                                )
                            ]
                        }
                    </div>,
                selectCoinsFunc = (toggleShowSelectCoins, coins, currentCoinLogo, currentCoinName, setCoinFunc = () => {
                }) => selectFunc(
                    true,
                    toggleShowSelectCoins,
                    showSelectCoins,
                    currentCoinLogo,
                    currentCoinName,
                    coins,
                    v => currentCoinName === v.name,
                    (v, i) => setCoinFunc(i),
                    v => v.logo,
                    v => v.name,
                    undefined,
                    true
                ),
                selectCoins = selectCoinsFunc(toggleShowSelectCoins, coins, currentCoinLogo, currentCoinName, setCoinIndex),
                coinsScroll = React.createRef(),
                coinsScrollFunc = (cC = coinsScroll, c = coins, coinsLengthKey = 'coinsLength', ...getListedMemeListParams) => {
                    const node = cC.current,
                        userWidth = isPhoneClientWidth && isLaunch
                    if (
                        c.length < checkData0(data, coinsLengthKey) &&
                        (node[userWidth ? 'scrollWidth' : 'scrollHeight'] -
                            node[userWidth ? 'scrollLeft' : 'scrollTop'] - 1 <=
                            node[userWidth ? 'clientWidth' : 'clientHeight']) && !showLoading
                    ) {
                        toggleShowLoading(true)
                        getListedMemeList(...getListedMemeListParams)
                    }
                },
                shortOverStatusList = checkDataObject(data, 'shortOverStatusList'),
                selectCoinsContentFunc = (coins, showSelectCoins, toggleShowSelectCoins, coinsScrollFunc = () => {
                }, setCoinFunc = () => {
                }, rGLML, isNotAll) => showSelectCoins &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.56)',
                         }}>
                        <div
                            className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone borderRadiusPhone' : 'childrenSpacingTopBigger'} flexColumn boxSizingBorderBox`}
                            style={{
                                height: `${isPhoneClientWidth ? 29 : 10}rem`,
                                width: isPhoneClientWidth ? '87%' : '10rem',
                                ...ternaryObject(
                                    !isPhoneClientWidth,
                                    {borderRadius: '.5rem'}
                                ),
                                padding: `${isPhoneClientWidth ? 1.5 : .7}rem`,
                                border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                            }}>
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div className={'saf'}
                                     style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                    Select a Token
                                </div>
                                <img onClick={() => toggleShowSelectCoins()}
                                     className={'cursorPointer'}
                                     src={requireDefault('close')}
                                     alt="" style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                            </div>
                            <div className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTopBig'}>
                                <div style={{borderTop: `solid ${isPhoneClientWidth ? .09 : .03}rem white`}}/>
                                <div
                                    className={`borderRadius999 backgroundWhite saf flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}
                                    style={{
                                        height: `${isPhoneClientWidth ? 1.5 : .5}rem`,
                                        padding: `${isPhoneClientWidth ? .15 : .05}rem`
                                    }}>
                                    <div onClick={() => searchListedMeme(undefined, rGLML)}
                                         className={'height100 borderRadius999 backgroundActiveColor flexCenter cursorPointer'}
                                         style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                        <img style={{width: '50%'}}
                                             src={requireDefault('search')} alt=""/>
                                    </div>
                                    <input value={searchSymbol} type="text"
                                           onChange={setSearchSymbol}
                                           placeholder={'Search name or paste address'}
                                           className={`flexGrow1 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}
                                           onKeyUp={e => searchSymbolOnKeyUp(e, () => searchListedMeme(undefined, rGLML))}
                                    />
                                    {searchSymbolIsNotNull && <img onClick={() => {
                                        setDataSearchSymbolEmpty()
                                        searchListedMeme(true, rGLML)
                                    }} style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}} className={'cursorPointer'}
                                                                   src={requireDefault('searchDisabled')}
                                                                   alt=""/>}
                                </div>
                            </div>
                            <div ref={coinsScroll}
                                 onScroll={coinsScrollFunc}
                                 className={`${isPhoneClientWidth ? 'childrenSpacingTopBig' : 'childrenSpacingTop'} flexGrow1Column overflowYScroll`}>
                                {coins.map((v, i) => (!isNotAll || !checkDataString(v, 'symbol').includes('LIKTEST') || currentCoinLongSymbol) &&
                                    <div style={{
                                        borderRadius: `${isPhoneClientWidth ? .9 : .3}rem`,
                                        border: `solid ${isPhoneClientWidth ? .09 : .03}rem white`,
                                        background: 'linear-gradient(270deg, #FFB9C2 0%, #FFF9F0 100%)'
                                    }}
                                         onClick={() => {
                                             setCoinFunc(i)
                                             toggleShowSelectCoins()
                                         }}
                                         className={`${isPhoneClientWidth ? 'paddingPhone paddingLeftRightBigPhone' : 'padding paddingLeftRightBig'} flexCenter justifyContentSpaceBetween childrenSpacingLeft cursorPointer`}>
                                        <div className={'childrenSpacingLeft flexCenter'}>
                                            <img className={'borderRadius999'} src={v.logo} alt=""
                                                 style={square(`${isPhoneClientWidth ? 2.1 : .7}rem`)}/>
                                            {isSwap && currentCoinLongSymbol && (() => {
                                                const shortOverStatus = [
                                                    ['Next', 1],
                                                    ['Available', .7],
                                                    ['Over', .5],
                                                ][checkData([shortOverStatusList, v.name], 2)]
                                                return Object.values(shortOverStatusList).length > 0 && <div
                                                    className={'borderRadius999 flexCenter saf1 backgroundBlack activeColor'}
                                                    style={{
                                                        background: `rgba(0, 0, 0, ${shortOverStatus[1]})`,
                                                        width: `${isPhoneClientWidth ? 2.4 : .8}rem`,
                                                        height: `${isPhoneClientWidth ? .9 : .3}rem`,
                                                        fontSize: `${isPhoneClientWidth ? .45 : .15}rem`,
                                                        border: `solid ${isPhoneClientWidth ? .03 : .01}rem #FFB9C2`,
                                                    }}
                                                >{shortOverStatus[0]}</div>
                                            })()}
                                            <div
                                                className={`saf1 ${isPhoneClientWidth ? '' : 'bigFontSize'}`}>{v.name}</div>
                                        </div>
                                        <img src={requireDefault('toRight')} alt=""
                                             style={square(`${isPhoneClientWidth ? 1.5 : .5}rem`)}/>
                                    </div>)}
                            </div>
                        </div>
                    </div>,
                selectCoinsContent = selectCoinsContentFunc(coins, showSelectCoins, toggleShowSelectCoins, () => coinsScrollFunc(), setCoinIndex, undefined, true),
                percentageToFixed = (num1, num2) => {
                    const num = npTimes(npDivide(num1, num2), 100),
                        toFixedNum = 2,
                        denominator = 10 ** toFixedNum
                    return `${Number(npDivide(Math.floor(npTimes(Number(isNaN(num) ? 0 : num), denominator)), denominator).toFixed(toFixedNum))}%`
                },
                defaultCountdown = ['00', '00', '00', '00'],
                countdown = checkData([data, `${currentCoinName}countdown`], defaultCountdown),
                countdownEnds = checkDataBool(data, `${currentCoinName}countdownEnds`),
                countdownEndsTrue = checkData([data, `${currentCoinName}countdownEnds`], true),
                setCountdown = (timestamp, blockNow, setIntervalCountdownKey = 'setIntervalCountdown', countdownEndsKey = 'countdownEnds', countdownKey = 'countdown') => {
                    timestamp = Number(timestamp)
                    const blockNowIsNotNull = isNotNull(blockNow)
                    let blockSeconds = npMinus(timestamp, checkData0(blockNow))
                    const setIntervalCountdown = checkData([data, setIntervalCountdownKey]),
                        setIntervalCountdownIsNotNull = isNotNull(setIntervalCountdown),
                        interval = setInterval(async () => {
                            const seconds = blockNowIsNotNull ? blockSeconds : (timestamp - Math.floor(Date.now() / 1000)),
                                d = Math.floor(seconds / (3600 * 24)),
                                h = Math.floor((seconds % (3600 * 24)) / 3600),
                                m = Math.floor((seconds % 3600) / 60),
                                s = seconds % 60,
                                countdownEnds = d <= 0 && h <= 0 && m <= 0 && s <= 0
                            setData({
                                [`${currentCoinName}${countdownEndsKey}`]: countdownEnds,
                                [`${currentCoinName}${countdownKey}`]: [d, h, m, s].map(v => v < 0 ? '00' : (v < 10 ? `0${v}` : v))
                            })
                            blockNowIsNotNull && blockSeconds--
                            if (countdownEnds) {
                                clearInterval(interval)
                                setIntervalCountdownIsNotNull && clearInterval(setIntervalCountdown)
                            }
                        }, 1000)
                    if (setIntervalCountdownIsNotNull) {
                        clearInterval(setIntervalCountdown)
                    }
                    setData({[setIntervalCountdownKey]: interval})
                },
                countdownContent = (v, i) => <div
                    className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeftBig'}`}>
                    {i !== 0 && (i === 1
                        ? <div
                            className={`saf ${isPhoneClientWidth ? '' : 'bigFontSize'}`}>DAYS</div>
                        : <img
                            src={requireDefault('Union')}
                            alt=""
                            style={{width: `${isPhoneClientWidth ? .25 : .1}rem`}}/>)}
                    <div className={'positionRelative'}>
                        <img
                            src={requireDefault('Rectangle')}
                            alt=""
                            style={{width: `${isPhoneClientWidth ? 2.5 : 1}rem`}}/>
                        <div
                            className={`saf positionAbsolute flexCenter width100 height100 top0 ${isPhoneClientWidth ? 'bigFontSizePhone' : 'biggerFontSize'}`}>
                            {v}
                        </div>
                    </div>
                </div>,
                inviteRecords = checkDataArray(data, 'inviteRecords'),
                inviteRecordsIsNotNullOrEmpty = isNotNullOrEmpty(inviteRecords),
                inviteRecordIndex = checkData0(data, 'inviteRecordIndex'),
                holders = checkData0(data, 'holders'),
                portfolioChains = memeSlaveContracts.filter(v => !isBNBFunc(v.id)),
                portfolioChainIndex = checkData0(data, 'portfolioChainIndex'),
                fromMaster = checkDataBool(data, 'fromMaster'),
                portfolioChain = portfolioChains[portfolioChainIndex],
                currentPortfolioChain = fromMaster ? portfolioChain : chainValue,
                crossToChainId = (fromMaster
                    ? currentPortfolioChain
                    : masterChain).id,
                setClaimAmount = () => {
                    const rCCC = funcName => rewardClaimContractCall(funcName, currentCoinName, address)
                    rCCC('getClaimAmount').then(claimAmount => rCCC('roundClaimAmount').then(roundClaimAmount => setData({
                        claimAmount,
                        claimed: numberETH(roundClaimAmount) > 0
                    })))
                },
                isClaimed = checkDataBool(data, 'claimed'),
                crossMax = checkData0(data, 'crossMax'),
                crossList = [
                    [isNewMeme ? currentCoinName : '', 'depositSelected', 'crossTo', slaveWriteContract,
                        () => getTokenBalance().then(tokenBalance => setData({tokenBalance})),
                        () => {
                            const setCrossToTokenBalance = tokenBalance =>
                                setData({crossToTokenBalance: numberETH(tokenBalance)})
                            if (fM) {
                                const portfolioChainId = portfolioChain.id
                                getContractAbi(portfolioChainId).then(slaveContractAbi => contractCall(
                                    new Web3(require(`./rpc/${portfolioChainId}`).default).eth,
                                    currentCoinContracts[portfolioChainId],
                                    slaveContractAbi,
                                    'balanceOf',
                                    address
                                ).then(setCrossToTokenBalance))
                            } else {
                                masterContractCall('balanceOf', address).then(setCrossToTokenBalance)
                            }
                        },
                        tokenBalance,
                        checkData0(data, 'crossToTokenBalance'),
                        rC,
                        'Token cross-chain',
                    ],
                    [isBNB ? '' : 'ETH', 'withdrawSelected', 'ethCrossTo', (...params) => wC(createMemeContractSlaveAddress, createMemeContractSlaveAbi, ...params),
                        () => getBalance().then(ethBalance => setData({ethBalance})),
                        () => (fM ? new Web3(require(`./rpc/${portfolioChain.id}`).default).eth : masterContractETH).getBalance(address).then(
                            ethBalance => setData({crossToETHBalance: numberETH(ethBalance)})),
                        ethBalance,
                        checkData0(data, 'crossToETHBalance'),
                        (funcName, ...params) => contractCall(slaveContractETH, createMemeContractSlaveAddress, createMemeContractSlaveAbi,
                            funcName, ...params
                        ),
                        `ETH cross-chain (LIMIT ${crossMax} ETH)`
                    ],
                ],
                crossItemIndex = isBNB ? 0 : (isNewMeme ? checkData0(data, 'crossItemIndex') : 1),
                crossItem = crossList[crossItemIndex],
                getCrossBalance = checkDataFunction(crossItem, 4),
                getCrossToBalance = checkDataFunction(crossItem, 5),
                crossCall = checkDataFunction(crossItem, 8),
                crossFunctionName = crossItem[2],
                isEthCrossTo = crossFunctionName === 'ethCrossTo',
                showPreviousTips = checkDataBool(data, 'showPreviousTips'),
                crossCallEstimateGas = amount => crossCall(
                    `${crossFunctionName}EstimateGas`,
                    crossToChainId,
                    address,
                    amount
                ),
                toggleShowPreviousTips = (showPreviousTips, closeShowLoading) => setData({
                    showPreviousTips,
                    ...ternaryObject(
                        closeShowLoading,
                        {showLoading: false}
                    )
                }),
                previousTips = showPreviousTips &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.56)',
                         }}
                    >
                        <div
                            className={isPhoneClientWidth ? 'childrenSpacingTopBiggerPhone boxSizingBorderBox borderRadiusPhone paddingBigPhone' : 'childrenSpacingTopBigger boxSizingBorderBox'}
                            style={isPhoneClientWidth
                                ? {
                                    width: '25rem',
                                    border: 'solid .15rem white',
                                    background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                }
                                : {
                                    width: '12rem',
                                    padding: '.7rem',
                                    borderRadius: '.5rem',
                                    border: 'solid .05rem white',
                                    background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                }}
                        >
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div className={'saf'}
                                     style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                    Tips
                                </div>
                                <img onClick={() => toggleShowPreviousTips(false, true)}
                                     className={'cursorPointer'}
                                     src={requireDefault('close')}
                                     alt="" style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                            </div>
                            <div
                                className={isPhoneClientWidth ? 'paddingBigPhone childrenSpacingTopBigPhone' : 'paddingBigger childrenSpacingTop'}>
                                <div className={'flexCenter'}>
                                    <img src={requireDefault('previousTips')} alt=""
                                         style={{width: `${isPhoneClientWidth ? 3.6 : 1.2}rem`}}/>
                                </div>
                                <pre className={`textAlignCenter ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}>{`The previous transaction has not been completed. 
Do you want to continue the new transaction?`}</pre>
                            </div>
                            <div
                                className={`${isPhoneClientWidth ? 'childrenSpacingLeftBigPhone' : 'childrenSpacingLeftBigger'} flexCenter childrenFlexGrow1`}>
                                {
                                    [
                                        ['cancel', () => {
                                            toggleShowPreviousTips(false, true)
                                        }],
                                        ['continue', () => {
                                            toggleShowPreviousTips()
                                            checkDataFunction(data, 'previousTipsFunc')()
                                        }],
                                    ].map(v => <div
                                        onClick={v[1]}
                                        className={'borderRadius999 backgroundBlack saf flexCenter cursorPointer width100 activeColor'}
                                        style={{
                                            padding: `${isPhoneClientWidth ? 1 : .25}rem 0`,
                                            fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`
                                        }}
                                    >{v}</div>)
                                }
                            </div>
                        </div>
                    </div>,
                cloudflareHuman = document.getElementById('cloudflareHuman'),
                signBiz = (biz, symbol, token, amount = 0, needData) => {
                    if (address) {
                        toggleShowLoading(true)
                        return requestApi(
                            'sign/biz',
                            {
                                biz,
                                amount,
                                symbol,
                                sender: address,
                                ...ternaryObject(
                                    token,
                                    {token}
                                ),
                            },
                            'post'
                        ).then(signTrue => {
                            setData({
                                signTrue,
                                ...ternaryObject(
                                    !needData,
                                    {showLoading: false}
                                )
                            })
                            return signTrue
                        })
                    }
                },
                titleTip = (text, ...params) => <div style={{background: 'rgba(255, 221, 225, .6)'}}
                                                     className={`${isPhoneClientWidth ? 'paddingPhone borderRadiusPhone childrenSpacingLeftSmallPhone' : 'padding borderRadius childrenSpacingLeft'} flex alignItemsCenter`}>
                    <img src={requireDefault('previousTips')} alt=""
                         style={{width: `${isPhoneClientWidth ? .6 : .3}rem`}}/>
                    <div style={{fontSize: `${isPhoneClientWidth ? .55 : .25}rem`}}>
                        {text}
                        {params}
                    </div>
                </div>,
                slippage = false && titleTip('During a short period of intense trading, a large slippage may occur.'),
                gasIsHigh = (functionName, estimateGas) => {
                    const estimateGasMax = pingFeeFunctionName.includes(functionName)
                        ? (isBNB ? 0.0025 : 0.00016)
                        : (pongFeeFunctionName.includes(functionName) ? (isBNB ? 0.005 : 0.000368) : null)
                    return isNotNull(estimateGasMax) && estimateGas > estimateGasMax && titleTip('The network is congested and the gas fee may be high.')
                },
                messagesIncludeCount = checkData0(data, 'messagesIncludeCount'),
                isMessagesInclude = messagesIncludeCount > 50,
                messagesInclude = isMessagesInclude && titleTip(
                    'The current queued transaction messages include: ',
                    <span style={{fontSize: `${isPhoneClientWidth ? .55 : .25}rem`}}
                          className={'saf1'}>{messagesIncludeCount}</span>,
                    <div
                        style={{fontSize: `${isPhoneClientWidth ? .55 : .25}rem`}}>{'If you continue to trade, it may take longer to receive the results.'}</div>
                ),
                isNext = checkData([shortOverStatusList, currentCoinName]) === 0,
                nextTip = isNext && titleTip(
                    'At present, the Token of this serial number cannot be purchased in principle, if it is purchased in advance, if the short selling has not been opened, the funds will be returned'
                ),
                showCancel = isMessagesInclude || isNext,
                confirmExchange = (toggleFunc, show, logo, logo1, name, name1, amount, amount1, functionName, estimateGas, func) => show &&
                    <div className={'positionFixed flexColumnCenter'}
                         style={{
                             top: 0,
                             left: 0,
                             right: 0,
                             bottom: 0,
                             zIndex: 1000,
                             background: 'rgba(0,0,0,.56)',
                         }}>
                        <div
                            className={`${isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTopBigger'} boxSizingBorderBox`}
                            style={{
                                width: `${isPhoneClientWidth ? 25 : 12}rem`,
                                padding: `${isPhoneClientWidth ? 1.5 : .7}rem`,
                                borderRadius: `${isPhoneClientWidth ? 1.5 : .5}rem`,
                                border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                            }}>
                            <div className={'flexCenter justifyContentSpaceBetween'}>
                                <div className={'saf'} style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                    Confirm exchange
                                </div>
                                <img onClick={() => toggleFunc()}
                                     className={'cursorPointer'}
                                     src={requireDefault('close')}
                                     alt="" style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                            </div>
                            <div style={{borderRadius: `${isPhoneClientWidth ? 1.2 : .4}rem`}}
                                 className={`backgroundWhite ${isPhoneClientWidth ? 'borderRadiusPhone childrenSpacingTopBigPhone paddingBigPhone' : 'borderRadius childrenSpacingTopBig paddingBigger'}`}
                            >
                                <div
                                    className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                                    {
                                        [
                                            [logo, name, amount],
                                            [logo1, name1, amount1],
                                        ].map((v, i) => [
                                            ...ternaryArray(
                                                i !== 0,
                                                [
                                                    <div className={'flexCenter'}>
                                                        <img src={requireDefault('SWAP2')} alt=""
                                                             style={{width: `${isPhoneClientWidth ? 2.1 : .7}rem`}}/>
                                                    </div>
                                                ]
                                            ),
                                            <div
                                                className={'flexCenter justifyContentSpaceBetween'}>
                                                <div
                                                    className={`flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                    <img src={v[0]} alt=""
                                                         style={{width: `${isPhoneClientWidth ? 1.5 : .7}rem`}}/>
                                                    <div className={'saf'}
                                                         style={{fontSize: `${isPhoneClientWidth ? 1 : .5}rem`}}>
                                                        {v[1]}
                                                    </div>
                                                </div>
                                                <div style={{fontSize: `${isPhoneClientWidth ? 1 : .5}rem`}}
                                                     className={'saf'}
                                                >
                                                    {v[2]}
                                                </div>
                                            </div>
                                        ])
                                    }
                                </div>
                                <div style={{border: `solid ${isPhoneClientWidth ? .06 : .02}rem #D8D8D8`}}/>
                                <div
                                    className={'flexCenter justifyContentSpaceBetween'}>
                                    {['Estimated Internet Fees', `${estimateGas} ${ethBNB}`].map(v => <div
                                        style={{fontSize: `${isPhoneClientWidth ? .7 : .28}rem`}}>{v}</div>)}
                                </div>
                                {gasIsHigh(functionName, estimateGas)}
                            </div>
                            <div
                                onClick={func}
                                className={'borderRadius999 backgroundBlack saf flexCenter biggerFontSize cursorPointer width100 activeColor'}
                                style={{
                                    padding: `${isPhoneClientWidth ? 1 : .25}rem 0`,
                                    fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`
                                }}>
                                CONFIRM
                            </div>
                        </div>
                    </div>,
                getAirdrop = `Airdropped tokens are purchased by the platform.

How can users receive a bigger airdrop?
Voting - ${checkData0(data, 'basicPoints')} points
Voting by your invitee - ${checkData0(data, 'parentsPoints')} points
Voting by your invitee's invitee - ${checkData0(data, 'grandpaPoints')} points

Claim upon listing: 
Total Airdrop Amount ➗ Total voting points ✖️ My voting points`,
                hardcapMax = checkData0(data, 'hardcapMax'),
                launchCountdownMin = checkData0(data, 'launchCountdownMin'),
                launchCountdownMax = checkData0(data, 'launchCountdownMax'),
                createMemeData = checkData(
                    [data, 'createMemeData'],
                    {
                        poh: 1,
                        swapPhase: 1,
                        enableShort: 1,
                        hardcap: hardcapMax,
                        tokenomics: defaultTokenomicsNum,
                        presale: presaleList[0][0],
                        launchFunds: presaleList[0][1],
                        launchCountdown: launchCountdownMin,
                        ...ternaryObject(
                            isContest,
                            {launchFunds: contestLaunchFunds}
                        )
                    }
                ),
                quotaInterval = checkDataArray(data, 'quotaInterval'),
                createMemeDataTokenomics = checkData0(createMemeData, 'tokenomics'),
                createMemeDataSymbol = checkData([createMemeData, 'symbol']),
                createMemeDataName = checkData([createMemeData, 'name']),
                createMemeDataTg = checkDataString(createMemeData, 'tg'),
                createMemeDataX = checkDataString(createMemeData, 'x'),
                createMemeDataTotalSupply = checkDataString(createMemeData, 'totalSupply'),
                createMemeNumberDataTotalSupply = Number(createMemeDataTotalSupply),
                symbolString = checkDataString(createMemeData, 'symbol'),
                createMemeDataLogo = checkDataString(createMemeData, 'logo'),
                createMemeDataLaunchCountdown = checkData([createMemeData, 'launchCountdown']),
                createMemeDataLogoIsNotNullOrEmpty = isNotNullOrEmpty(createMemeDataLogo),
                createMemeDataIsTokenomicsAuto = isTokenomicsAuto(createMemeDataTokenomics),
                createMemeDataLogoShow = name => createMemeDataLogoIsNotNullOrEmpty
                    ? memeLogo(createMemeDataLogo)
                    : requireDefault(name),
                createMemeDataQuota = checkData([createMemeData, 'quota'], createMemeDataIsTokenomicsAuto ? checkData0(quotaInterval, 0) : 0),
                createMemeDataQuotaETH = parseEtherWei(npDivide(createMemeDataQuota, 100)),
                createMemeDataLaunchFunds = checkData([createMemeData, 'launchFunds']),
                createMemeDataNumberLaunchFunds = Number(createMemeDataLaunchFunds),
                listingVotesMinRatio = checkData0(data, 'listingVotesMinRatio'),
                createMemeDataInvitationCode = checkDataString(createMemeData, 'invitationCode'),
                createMemeDataLaunchPhase = checkData([createMemeData, 'launchPhase']),
                createMemeDataNumberLaunchPhase = Number(createMemeDataLaunchPhase),
                presaleMin = 10,
                presaleMax = 50,
                createMemeDataPresale = checkData([createMemeData, 'presale'], presaleMin),
                totalSupplyMin = checkData0(data, 'totalSupplyMin'),
                totalSupplyMax = checkData0(data, 'totalSupplyMax'),
                launchFundsMin = checkData0(data, 'launchFundsMin'),
                launchFundsMax = checkData0(data, 'launchFundsMax'),
                createMemeDataNumberPresale = Number(createMemeDataPresale),
                launchPhaseMin = 0.001,
                launchPhaseMax = 5,
                symbolCheck = [/^[A-Z0-9]+$/.test(symbolString) && !symbolExists, symbolExists ? 'Already exists' : 'Symbol must be uppercase'],
                totalSupplyCheck = [createMemeNumberDataTotalSupply >= totalSupplyMin && createMemeNumberDataTotalSupply <= totalSupplyMax, 'Total supply error'],
                launchFundsCheck = [symbolString.indexOf('LIKTEST') === 0 || (createMemeDataNumberLaunchFunds >= launchFundsMin && createMemeDataNumberLaunchFunds <= launchFundsMax), 'Launch funds error'],
                presaleCheck = [true, 'Presale error'],
                launchPhaseCheck = [createMemeDataNumberLaunchPhase >= launchPhaseMin && createMemeDataNumberLaunchPhase <= launchPhaseMax, 'Limit error'],
                getCreatePriceParams = [
                    createMemeDataTokenomics,
                    createMemeDataQuotaETH,
                    parseEtherWei(checkData0(createMemeDataLaunchFunds))
                ],
                getCreateVotePrice = () => {
                    // console.log(getCreatePriceParams);
                    return createMemeContractSlaveCall(
                        isVoteDetail ? 'votePrice' : 'getCreateAmount',
                        ...ternaryArray(
                            !isVoteDetail,
                            getCreatePriceParams
                        )
                    ).then(price => ethToBNB(price).then(price => bnbAmountMultiple(price)))
                },
                showCreateMeme = checkDataBool(data, 'showCreateMeme'),
                toggleShowCreateMeme = showCreateMeme => {
                    // todo 活动
                    if (showCreateMeme && isContest) {
                        toast('We are going to postpone Phase 2 because LIKWID is now planning to launch a new version, which will have more benefits for creators')
                    } else {
                        const setShowCreateMeme = () => setData({showCreateMeme})
                        showCreateMeme
                            ? switchEthereumChain(setShowCreateMeme, undefined, true)
                            : setShowCreateMeme()
                    }
                },
                createVotePrice = checkData0(data, 'createVotePrice'),
                // createVotePrice = (() => {
                //     const cVP = checkData0(data, 'createVotePrice')
                //     return isVoteDetail ? cVP : parseEtherWei(npPlus(numberETH(cVP), 10 ** -toFixedNum))
                // })(),
                createVotePriceETH = numberETH(createVotePrice),
                voteMeme = checkDataObject(data, 'voteMeme'),
                inviteAddress = checkData(
                    [new URLSearchParams(location.search).get('invite')],
                    addressZero
                ),
                voteCreatFunctionName = isVoteDetail ? 'vote' : 'create',
                createMemeDataParams = [
                    address,
                    checkDataString(createMemeDataSymbol).toLocaleUpperCase(),
                    `${namePrefix}${createMemeDataName}`,
                    createMemeDataLogo,
                    parseEtherWei(createMemeNumberDataTotalSupply),
                    parseEtherWei(createMemeDataNumberLaunchFunds),
                    7200,//npTimes(Number(createMemeDataLaunchCountdown), 3600),
                    createMemeDataTg,
                    [
                        createMemeDataX, createMemeDataNumberPresale,
                        checkData([createMemeData, 'swapPhase'], 1),
                        createMemeDataLaunchPhase,
                        checkData([createMemeData, 'poh']) == 2 ? true : false,
                        checkData([createMemeData, 'enableShort']) == 2 ? true : false,
                    ].join(xCodeSplit),
                    createMemeDataIsTokenomicsAuto ? createMemeDataQuotaETH : 0,
                    parseEtherWei(npPlus(createMemeDataNumberLaunchFunds, 1)),//parseEtherWei(checkData0(createMemeData, 'hardcap')),
                    createMemeDataTokenomics,
                    0,
                ],
                toggleShowVote = async showVote => {
                    if (showVote) {
                        const errorFunc = () => {
                            toggleShowLoading()
                            toast('Unknown error')
                        }
                        if (isVoteDetail) {
                            // if (!signTrue) {
                            //     toastVerifyHuman()
                            //     return
                            // }
                        } else {
                            const symbolForbiddenWords = 'LIKWID'
                            if (symbolString.includes(symbolForbiddenWords)) {
                                toast(`Symbol cannot contain ${symbolForbiddenWords}`)
                                return
                            }
                            if (!isNotNullOrEmpty(symbolString)) {
                                toast('Symbol cannot be empty')
                                return
                            }
                            toggleShowLoading(true)
                            if (await tokenMemeDetailFunc(symbolString).then(v => {
                                const exists = Object.keys(checkDataObject(v)).length > 0;
                                exists && toggleShowLoading();
                                toggleSymbolExists(exists);
                                return exists
                            }).catch(errorFunc)) {
                                toast('Already exists')
                                toggleShowLoading()
                                return
                            }
                            if (!createMemeDataLogoIsNotNullOrEmpty) {
                                toast('Logo cannot be empty')
                                toggleShowLoading()
                                return
                            }
                            if (!symbolCheck[0]) {
                                toast(symbolCheck[1])
                                toggleShowLoading()
                                return
                            }
                            if (!totalSupplyCheck[0]) {
                                toast(totalSupplyCheck[1])
                                toggleShowLoading()
                                return
                            }
                            if (!launchFundsCheck[0]) {
                                toast(launchFundsCheck[1])
                                toggleShowLoading()
                                return
                            }
                            if (!presaleCheck[0]) {
                                toast(presaleCheck[1])
                                toggleShowLoading()
                                return
                            }
                            if (!launchPhaseCheck[0]) {
                                toast(launchPhaseCheck[1])
                                toggleShowLoading()
                                return
                            }
                        }
                        new Promise(resolve => resolve(true))
                            .then(async v => {
                                if (v) {
                                    const createVotePrice = await getCreateVotePrice().catch(errorFunc)
                                    switchEthereumChain(
                                        async () => {
                                            setData({
                                                showVote,
                                                createVotePrice,//: numberETH(createVotePrice),
                                                showLoading: false,
                                                estimateGas: npTimes(
                                                    await (pingEstimateGas(
                                                        createMemeContractSlaveCall,
                                                        voteCreatFunctionName,
                                                        createVotePrice,
                                                        ...(isVoteDetail ? [voteMeme.symbol, inviteAddress] : [createMemeDataParams])
                                                    ).catch(errorFunc)),
                                                    1
                                                )
                                            })
                                        }, errorFunc, true)
                                } else {
                                    toast('Invitation Code Error')
                                }
                            })
                    } else {
                        setData({showVote})
                    }
                },
                createMemeContent = (() => {
                    const setCreateMemeData = async cMD => setData({createMemeData: {...createMemeData, ...cMD}}),
                        quotaPercentage = `${npMinus(100, Number(createMemeDataQuota))}%`,
                        setInputCreateMemeData = (e, k) => {
                            const {value} = e.target,
                                isSymbol = k === 'symbol'
                            setCreateMemeData({
                                [k]: k === 'name'
                                    ? value.replace(/.{101,}/g, '') : (
                                        isSymbol
                                            ? value.replace(/.{15,}/g, '')//.replace(/[^a-zA-Z0-9]/g, '').replace(/.{11,}/g, '').toLocaleUpperCase()
                                            : (k === 'launchFunds'
                                                ? (() => {
                                                    const vN = Number(value)
                                                    return vN >= 1
                                                        ? value.replace(/[^0-9]/g, '')
                                                        : value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, ".")
                                                            .replace(/^\./g, "").replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
                                                            .replace(/^(\-)*(\d+)\.(\d{1}).*$/, '$1$2.$3')
                                                })()
                                                : (['totalSupply', 'launchCountdown', 'hardcap'].includes(k)
                                                    ? value.replace(/[^0-9]/g, '')
                                                    : (
                                                        k === 'quota'
                                                            ? (quotaInterval.includes(Number(value))
                                                                ? value
                                                                : '')
                                                            : (
                                                                k === 'presale'
                                                                    ? value.replace(/[^0-9]/g, '').replace(/.{3,}/g, '')
                                                                    : (
                                                                        k === 'launchPhase'
                                                                            ? amountReplace((value))
                                                                            : value
                                                                    )
                                                            )
                                                    )))
                                    )
                            });
                            isSymbol && toggleSymbolExists(false)
                        },
                        voteCountdown = countdown.map(countdownContent),
                        radio = [
                            // quotaData,
                            // howToVoteData,
                            ['The Transaction Fee (Swap Phase) ', 'swapPhase', [
                                '1%  (The community will receive 0.5%)',
                                '2%  (The community will receive 1%)',
                            ]],
                            ['Enable real person verification（POH）', 'poh', [
                                'close',
                                'open',
                            ]],
                            ['Allow Shorting Token', 'enableShort', [
                                'close',
                                'open',
                            ]],
                            //
                        ],
                        radioContent = (v, noSelect) => <div
                            className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}>
                            <div className={`saf1 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}>{v[0]}</div>
                            {
                                (() => {
                                    const key = v[1],
                                        list = v[2],
                                        isTokenomics = key === 'tokenomics',
                                        radioText = (text, i, content) => <div
                                            onClick={isTokenomics ? undefined : (() => setCreateMemeData({[key]: i + 1}))}
                                            className={`flex ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'} cursorPointer`}>
                                            {!noSelect && <div
                                                className={'borderRadius999 backgroundWhite flexCenter'}
                                                style={square(`${isPhoneClientWidth ? 1.2 : .4}rem`)}>
                                                {checkData0(createMemeData, key) - 1 === i &&
                                                    <div style={square('60%')}
                                                         className={'borderRadius999 backgroundRed'}/>}
                                            </div>}
                                            <div className={`flexGrow1`}>
                                                {text && <div
                                                    className={isPhoneClientWidth ? 'smallFontSizePhone' : ''}>{text}</div>}
                                                {content}
                                            </div>
                                        </div>
                                    return list.map((v1, i) =>
                                        isTokenomics
                                            ? i + 1 === defaultTokenomicsNum && radioText(
                                            undefined,
                                            i,
                                            <div className={isPhoneClientWidth ? 'smallFontSizePhone' : ''}>
                                                <div
                                                    style={{paddingTop: isPhoneClientWidth ? '.3rem' : '.1rem'}}/>
                                                <div
                                                    style={{borderRadius: `${isPhoneClientWidth ? .3 : .1}rem`}}
                                                    className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'}`}>
                                                    {/*<input*/}
                                                    {/*    value={createMemeDataPresale}*/}
                                                    {/*    style={{width: `${isPhoneClientWidth ? 1.5 : 1}rem`}}*/}
                                                    {/*    onChange={e => setInputCreateMemeData(e, 'presale')}*/}
                                                    {/*    className={`height100 saf1 width100 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}*/}
                                                    {/*    type="text"/>*/}
                                                    {
                                                        presaleList.map(v => {
                                                            const [presale, launchFunds] = v,
                                                                isCurrent = createMemeDataPresale === presale
                                                            return <div
                                                                onClick={() => setCreateMemeData({presale, launchFunds})}
                                                                className={`saf1 ${isCurrent ? 'colorWhite' : ''} ${isPhoneClientWidth ? 'paddingSmallPhone smallFontSizePhone' : 'paddingSmall'} boxSizingBorderBox`}
                                                                style={isCurrent
                                                                    ? {background: '#FF7788'}
                                                                    : {
                                                                        background: 'rgba(255, 119, 136, .2)',
                                                                        border: `solid ${isPhoneClientWidth ? .15 : .05}rem rgba(255,255,255,.2)`
                                                                    }}
                                                            >{presale}%</div>
                                                        })
                                                    }
                                                    <div
                                                        className={isPhoneClientWidth ? 'smallFontSizePhone' : ''}>presale
                                                    </div>
                                                </div>
                                                <span
                                                    className={`saf1 ${isPhoneClientWidth ? 'smallFontSizePhone' : ''}`}>{`${npMinus(100, createMemeDataNumberPresale)}%`}</span>{' pool locked in trading curve'}
                                            </div>
                                        )
                                            : radioText(v1, i))
                                    // return [
                                    // ...,
                                    // ...ternaryArray(
                                    //     key === 'tokenomics',
                                    //     [
                                    //         radioText(
                                    //             'Presale (10% - 40%)',
                                    //             list.length,
                                    //             createMemeDataIsTokenomicsAuto &&
                                    //             <div>{`${tradingCurve}% pool locked in trading curve, `}<span
                                    //                 className={'saf1'}
                                    //                 style={{color: '#FF7788'}}>{quotaPercentage}</span>{' presale, '}
                                    //                 <div
                                    //                     style={{paddingTop: isPhoneClientWidth ? 0 : '.1rem'}}/>
                                    //                 <span
                                    //                     style={{borderRadius: `${isPhoneClientWidth ? .3 : .1}rem`}}
                                    //                     className={`saf1 ${isPhoneClientWidth ? 'paddingSmallPhone' : 'paddingSmall'} backgroundWhite`}><input
                                    //                     value={createMemeDataQuota}
                                    //                     style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}
                                    //                     onChange={e => setInputCreateMemeData(e, 'quota')}
                                    //                     className={`height100 saf1 textAlignCenter width100 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}
                                    //                     type="text"/></span>{` % earmarked presale for creator, ${airdrop}% airdrop `}
                                    //             </div>
                                    //         )
                                    //     ]
                                    // )
                                    // ]
                                })()
                            }
                        </div>
                    return showCreateMeme &&
                        <div className={'positionFixed flexColumnCenter'}
                             style={{
                                 top: 0,
                                 left: 0,
                                 right: 0,
                                 bottom: 0,
                                 zIndex: 1000,
                                 background: 'rgba(0,0,0,.56)',
                             }}>
                            <div
                                className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone borderRadiusPhone' : 'childrenSpacingTopBigger'} 
                                        boxSizingBorderBox overflowYScroll`}
                                style={{
                                    width: isPhoneClientWidth ? '90%' : '12rem',
                                    maxHeight: '100vh',
                                    ...ternaryObject(
                                        !isPhoneClientWidth,
                                        {borderRadius: '.5rem'}
                                    ),
                                    padding: `${isPhoneClientWidth ? 1.5 : .7}rem`,
                                    border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                    background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                }}>
                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                    <div className={'saf'}
                                         style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                        CREATE A MEME
                                    </div>
                                    <img onClick={() => toggleShowCreateMeme()}
                                         className={'cursorPointer'}
                                         src={requireDefault('close')}
                                         alt="" style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                                </div>
                                <div
                                    className={isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}>
                                    {
                                        [
                                            [['Name Or Description', undefined, undefined, true], 'name', createMemeDataName, 'Orbit guy is a flying saucer pilot from orbiter'],
                                            [['Symbol', undefined, undefined, ...symbolCheck], 'symbol', createMemeDataSymbol, 'ORBGUY'],
                                            ['Logo (SIZE: 500KB, TYPE: png, jpg, jpeg, gif)', 'logo'],
                                            [['Omnichain Total Supply', undefined, undefined, ...totalSupplyCheck], 'totalSupply', createMemeDataTotalSupply, [totalSupplyMin, totalSupplyMax].join(' - ')],
                                            ['Tokenomics (No Fund Lose in Presale）', 'tokenomics'],
                                            [[`Launch Funds`, 'ETH', true, ...launchFundsCheck], 'launchFunds', createMemeDataLaunchFunds, launchFundsMin],
                                            [['Limit For A Single Address (Launch Phase)', 'ETH', true, ...launchPhaseCheck], 'launchPhase', createMemeDataLaunchPhase, `${launchPhaseMin} - ${launchPhaseMax}`],
                                            // [`Launch Hardcap (MAX ${hardcapMax} ETH)`, 'hardcap', checkData([createMemeData, 'hardcap']), hardcapMax],
                                            // [`Launch Countdown (HOUR ${[launchCountdownMin, launchCountdownMax].join('-')})`, 'launchCountdown', createMemeDataLaunchCountdown, launchCountdownMax],
                                            [[`Telegram (optional)`, tgUrl, undefined, true], 'tg', createMemeDataTg, 'likwid_fi'],
                                            [[`X.com (optional)`, xUrl, undefined, true], 'x', createMemeDataX, 'likwid_fi'],
                                            // ['Invitation Code', 'invitationCode', createMemeDataInvitationCode],
                                        ].map(v => {
                                            const [v0] = v, v0IsObject = typeof v0 === 'object',
                                                title = v0IsObject ? v0[0] : v0,
                                                key = v[1],
                                                value = v[2],
                                                checkFailed = v0IsObject && isNotNullOrEmpty(value) && !v0[3];
                                            return key === 'tokenomics'
                                                ? radioContent((() => {
                                                    const tD = [...tokenomicsData()]
                                                    tD[0] = title
                                                    return tD
                                                })(), true)
                                                : <div
                                                    className={isPhoneClientWidth ? 'childrenSpacingTopPhone' : 'childrenSpacingTop'}>
                                                    <div
                                                        className={`flex alignItemsCenter justifyContentSpaceBetween ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                                        <div
                                                            className={`saf1 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}>{title}</div>
                                                        {checkFailed && <div
                                                            className={`saf1 colorRed ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}>{v0[4]}</div>}
                                                        {/*{*/}
                                                        {/*    key === 'invitationCode' && <div*/}
                                                        {/*        style={{*/}
                                                        {/*            padding: `${isPhoneClientWidth ? .15 : .05}rem`,*/}
                                                        {/*            paddingRight: `${isPhoneClientWidth ? .3 : .1}rem`*/}
                                                        {/*        }}*/}
                                                        {/*        onClick={() => windowOpen('https://t.me/likwidofficial')}*/}
                                                        {/*        className={`backgroundWhite flexCenter ${isPhoneClientWidth ? 'childrenSpacingLeftSmallPhone' : 'childrenSpacingLeftSmall'} borderRadius999 cursorPointer`}>*/}
                                                        {/*        <img src={requireDefault('telegram1')} alt=""*/}
                                                        {/*             style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>*/}
                                                        {/*        <div*/}
                                                        {/*            className={`saf1 ${isPhoneClientWidth ? 'smallFontSizePhone' : 'smallFontSize'}`}>Telegram*/}
                                                        {/*        </div>*/}
                                                        {/*        <img src={requireDefault('toRightBlack')} alt=""*/}
                                                        {/*             style={{width: `${isPhoneClientWidth ? .6 : .2}rem`}}/>*/}
                                                        {/*    </div>*/}
                                                        {/*}*/}
                                                    </div>
                                                    {key !== 'logo'
                                                        ? <div className={'flexCenter childrenSpacingLeft'}>
                                                            <div
                                                                className={`${isPhoneClientWidth ? 'paddingPhone borderRadiusPhone childrenSpacingLeftPhone' : 'padding borderRadius childrenSpacingLeft'} backgroundWhite boxSizingBorderBox flexGrow1 flex alignItemsCenter`}
                                                                style={{height: `${isPhoneClientWidth ? 2.7 : .9}rem`, ...ternaryObject(checkFailed, {border: `${isPhoneClientWidth ? '.15rem' : '.05rem'} solid red`})}}>
                                                                {
                                                                    (() => {
                                                                        const text = v0[1], arr = [
                                                                            ...ternaryArray(
                                                                                v0IsObject && isNotNullOrEmpty(text),
                                                                                [
                                                                                    <div style={{
                                                                                        background: '#F7F7F7',
                                                                                        borderRadius: `${isPhoneClientWidth ? .3 : .1}rem`
                                                                                    }}
                                                                                         className={`${isPhoneClientWidth ? 'smallerFontSizePhone paddingSmallPhone' : 'paddingSmall'}`}>{text}</div>
                                                                                ]
                                                                            ),
                                                                            <input placeholder={v[3]}
                                                                                   value={v[2]}
                                                                                   disabled={v[4]}
                                                                                   onChange={e => setInputCreateMemeData(e, key)}
                                                                                   className={`height100 flexGrow1 saf1 width100 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}
                                                                                   type="text"/>
                                                                        ]
                                                                        return v0IsObject && v0[2] ? arr.reverse() : arr
                                                                    })()
                                                                }
                                                            </div>
                                                        </div>
                                                        : <div
                                                            className={`${isPhoneClientWidth ? 'paddingBigPhone borderRadiusPhone' : 'paddingBig borderRadius'} positionRelative backgroundWhite boxSizingBorderBox`}>
                                                            <div
                                                                className={'childrenSpacingTop flexColumnCenter'}>
                                                                <img src={createMemeDataLogoShow('upload')} alt=""
                                                                     style={{width: `${isPhoneClientWidth ? 6 : 2}rem`}}/>
                                                                <div
                                                                    className={isPhoneClientWidth ? 'smallFontSizePhone' : ''}>
                                                                    Drag and Drop file here or Choose file
                                                                </div>
                                                            </div>
                                                            <input type="file"
                                                                   id='uploadLogo'
                                                                   onChange={event => {
                                                                       toggleShowLoading(true)
                                                                       const formData = new FormData(),
                                                                           removeValue = () => document.getElementById('uploadLogo').value = null;
                                                                       formData.append('file', event.target.files[0]);
                                                                       requestApi(
                                                                           'upload/logo', formData, 'post',
                                                                           {'Content-Type': 'multipart/form-data'}
                                                                       ).then(logo => {
                                                                           if (isNotNullOrEmpty(logo)) {
                                                                               setCreateMemeData({logo})
                                                                           } else {
                                                                               removeValue()
                                                                           }
                                                                           toggleShowLoading()
                                                                       }).catch(removeValue)
                                                                   }}
                                                                   className={'backgroundRed positionAbsolute cursorPointer'}
                                                                   style={{
                                                                       top: 0,
                                                                       bottom: 0,
                                                                       left: 0,
                                                                       right: 0,
                                                                       opacity: 0,
                                                                   }}
                                                            />
                                                        </div>
                                                    }
                                                    {/*{key === 'launchFunds' && <div*/}
                                                    {/*    className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone borderRadiusPhone paddingPhone' : 'childrenSpacingLeft borderRadius padding'}`}*/}
                                                    {/*    style={{background: 'rgba(255,255,255,.7)'}}*/}
                                                    {/*>*/}
                                                    {/*    <img src={requireDefault('tips')} alt=""*/}
                                                    {/*         style={square(`${isPhoneClientWidth ? .7 : .3}rem`)}/>*/}
                                                    {/*    {*/}
                                                    {/*        (() => {*/}
                                                    {/*            const nLF = isNaN(createMemeDataNumberLaunchFunds) ? 0 : createMemeDataNumberLaunchFunds*/}
                                                    {/*            return <div*/}
                                                    {/*                style={{*/}
                                                    {/*                    lineHeight: 1.5,*/}
                                                    {/*                    fontSize: `${isPhoneClientWidth ? .6 : .25}rem`*/}
                                                    {/*                }}>At least <span*/}
                                                    {/*                style={{*/}
                                                    {/*                    color: '#FF8998',*/}
                                                    {/*                    fontSize: `${isPhoneClientWidth ? .6 : .25}rem`*/}
                                                    {/*                }}*/}
                                                    {/*                className={`saf1`}>{Math.floor(npTimes(nLF, listingVotesMinRatio))}</span> votes*/}
                                                    {/*                are required to reach <span*/}
                                                    {/*                    style={{*/}
                                                    {/*                        color: '#FF8998',*/}
                                                    {/*                        fontSize: `${isPhoneClientWidth ? .6 : .25}rem`*/}
                                                    {/*                    }}*/}
                                                    {/*                    className={`saf1`}>{nLF} ETH</span> launch*/}
                                                    {/*                funds*/}
                                                    {/*            </div>*/}
                                                    {/*        })()*/}
                                                    {/*    }*/}
                                                    {/*</div>}*/}
                                                </div>
                                        })
                                    }
                                    {radio.map(v => radioContent(v))}
                                    {/*{*/}
                                    {/*    createMemeDataIsTokenomicsAuto &&*/}
                                    {/*    <div*/}
                                    {/*        className={`${isPhoneClientWidth ? 'borderRadiusPhone paddingBigPhone childrenSpacingLeftPhone' : 'borderRadius paddingBig childrenSpacingLeft'} flex`}*/}
                                    {/*        style={{background: 'rgba(255,255,255,.7)'}}*/}
                                    {/*    >*/}
                                    {/*        <img src={requireDefault('previousTips')} alt=""*/}
                                    {/*             style={square(`${isPhoneClientWidth ? 1.2 : .4}rem`)}/>*/}
                                    {/*        <div style={{lineHeight: 1.5}}*/}
                                    {/*             className={isPhoneClientWidth ? 'smallerFontSizePhone' : ''}><span*/}
                                    {/*            className={`saf1 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}*/}
                                    {/*            style={{color: '#FF7788'}}>{createMemeDataQuota}% </span>Earmarked*/}
                                    {/*            presale*/}
                                    {/*            requires a payment of <div*/}
                                    {/*                className={`saf1 ${isPhoneClientWidth ? 'smallerFontSizePhone' : ''}`}*/}
                                    {/*                style={{color: '#FF7788'}}>{createVotePriceETH} ETH</div>*/}
                                    {/*            <div*/}
                                    {/*                className={isPhoneClientWidth ? 'smallerFontSizePhone' : 'smallFontSize'}>If*/}
                                    {/*                your community voting*/}
                                    {/*                does*/}
                                    {/*                not reach*/}
                                    {/*                the estimated target, please consider this model*/}
                                    {/*                thoughtfully.*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*}*/}
                                </div>
                                <div
                                    onClick={() => toggleShowVote(true)}
                                    className={'borderRadius999 backgroundBlack saf flexCenter biggerFontSize cursorPointer width100 activeColor'}
                                    style={{
                                        padding: `${isPhoneClientWidth ? 1 : .25}rem 0`,
                                        fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`
                                    }}>
                                    CONFIRM
                                </div>
                            </div>
                        </div>
                })(),
                voteCreateContent = (() => {
                    const voteContent = isVoteDetail ? voteMeme : createMemeData,
                        estimateGasETH = numberETH(estimateGas)
                    return checkDataBool(data, 'showVote') &&
                        <div className={'positionFixed flexColumnCenter'}
                             style={{
                                 top: 0,
                                 left: 0,
                                 right: 0,
                                 bottom: 0,
                                 zIndex: 1000,
                                 background: 'rgba(0,0,0,.56)',
                             }}>
                            <div
                                className={`${isPhoneClientWidth ? 'childrenSpacingTopBigPhone borderRadiusPhone' : 'childrenSpacingTopBigger'} boxSizingBorderBox`}
                                style={{
                                    width: isPhoneClientWidth ? '90%' : '12rem',
                                    maxHeight: '100vh',
                                    ...ternaryObject(
                                        !isPhoneClientWidth,
                                        {borderRadius: '.5rem'}
                                    ),
                                    padding: `${isPhoneClientWidth ? 1.5 : .7}rem`,
                                    border: `solid ${isPhoneClientWidth ? .15 : .05}rem white`,
                                    background: 'linear-gradient(180deg, #FFF9F0 0%, #FFB9C2 50.06%)',
                                }}>
                                <div className={'flexCenter justifyContentSpaceBetween'}>
                                    <div className={'saf'}
                                         style={{fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`}}>
                                        {isVoteDetail ? 'voting information' : 'CREATE A MEME'}
                                    </div>
                                    <img onClick={() => toggleShowVote()}
                                         className={'cursorPointer'}
                                         src={requireDefault('close')}
                                         alt="" style={{width: `${isPhoneClientWidth ? 1.5 : .5}rem`}}/>
                                </div>
                                <div
                                    className={`borderRadiusPhone backgroundWhite ${isPhoneClientWidth ? 'paddingBigPhone' : 'paddingBigger'}
                                             ${isPhoneClientWidth ? 'childrenSpacingTopBigPhone' : 'childrenSpacingTopBig'}`}
                                    style={{
                                        ...ternaryObject(
                                            !isPhoneClientWidth,
                                            {borderRadius: '.5rem'}
                                        ),
                                    }}
                                >
                                    <div
                                        className={`flex alignItemsCenter ${isPhoneClientWidth ? 'childrenSpacingLeftPhone' : 'childrenSpacingLeft'}`}>
                                        <div style={square(`${isPhoneClientWidth ? 3 : 1}rem`)}>
                                            <img
                                                src={isVoteDetail ? voteContent.logo : createMemeDataLogoShow('LIKWID')}
                                                alt=""
                                                className={'width100 height100'}/>
                                        </div>
                                        <div
                                            className={`saf1 ${isPhoneClientWidth ? 'bigFontSizePhone' : 'bigFontSize'}`}>
                                            {voteContent.symbol}
                                        </div>
                                    </div>
                                    {
                                        [
                                            ['Total Supply', voteContent.totalSupply],
                                            ['Launch Funds (ETH)', voteContent.launchFunds],
                                        ].map(v => <div
                                            className={'flexCenter justifyContentSpaceBetween'}>
                                            {v.map(v => <div
                                                style={{fontSize: `${isPhoneClientWidth ? .9 : .28}rem`}}>{v}</div>)}
                                        </div>)
                                    }
                                    <div
                                        className={'flexCenter justifyContentSpaceBetween'}>
                                        {
                                            ['Payable', `${createVotePriceETH} ${ethBNB}`].map(v =>
                                                <div
                                                    className={'saf'}
                                                    style={{fontSize: `${isPhoneClientWidth ? 1.2 : .4}rem`}}>{v}</div>)}
                                    </div>
                                    <div style={{border: 'solid .02rem #D8D8D8'}}/>
                                    <div
                                        className={'flexCenter justifyContentSpaceBetween'}>
                                        {['Estimated Internet Fees', `${estimateGasETH} ${ethBNB}`].map(v =>
                                            <div
                                                style={{fontSize: `${isPhoneClientWidth ? .9 : .28}rem`}}>{v}</div>)}
                                    </div>
                                    {gasIsHigh(voteCreatFunctionName, estimateGasETH)}
                                </div>
                                {isVoteDetail && !signTrue && cloudflareHumanDiv}
                                <div
                                    onClick={async () => {
                                        const {symbol: voteMemeSymbol} = voteMeme
                                        if (isVoteDetail) {
                                            if (!signTrue) {
                                                toastVerifyHuman()
                                                return
                                            }
                                        } else {
                                            if (await createMemeContractMasterCall('memeIsListed', createMemeDataSymbol)) {
                                                toast('Already listing')
                                                return
                                            }
                                        }
                                        toggleShowLoading(true)
                                        // console.log(createMemeDataParams, '+++++++++++++=');
                                        createMemeSlaveWriteContract(
                                            voteCreatFunctionName,
                                            BigInt(
                                                npPlus(
                                                    createVotePrice,
                                                    estimateGas
                                                )),
                                            isVoteDetail ? [voteMemeSymbol, inviteAddress, signTrue] : [createMemeDataParams],
                                            hash => (() => {
                                                const tokenMeme = () => requestApi('token/meme', {
                                                    chainId,
                                                    tx: hash,
                                                    // code: createMemeDataInvitationCode,
                                                }, 'post')
                                                return waitForTransaction({
                                                    hash,
                                                    timeout: pingCrossChainTime
                                                }).then(tokenMeme).catch(tokenMeme)
                                            })().then(
                                                r => {
                                                    if (r && !isVotePage) {
                                                        windowReplace(`/launch/${createMemeDataSymbol}`)
                                                    } else {
                                                        if (isVoteDetail) {
                                                            getVoteMeme()
                                                            getAllVotePoints()
                                                            getVotePointsChildrenVoted()
                                                        } else if (isVotePage) {
                                                            getRoundMemeList()
                                                        } else if (!isContest) {
                                                            reGetListedMemeList(undefined, isLaunch && r)
                                                        }
                                                        toastSuccessfulHash(r)
                                                        toggleShowVote()
                                                        toggleShowCreateMeme()
                                                        toggleShowLoading()
                                                    }
                                                }
                                            )
                                        )
                                    }}
                                    className={'borderRadius999 backgroundBlack saf flexCenter biggerFontSize cursorPointer width100 activeColor'}
                                    style={{
                                        padding: `${isPhoneClientWidth ? 1 : .25}rem 0`,
                                        fontSize: `${isPhoneClientWidth ? 1.5 : .5}rem`
                                    }}>
                                    CONFIRM
                                </div>
                            </div>
                        </div>
                })(),
                contractAddressContent = (() => {
                    const showContractAddress = checkDataBool(data, 'showContractAddress'),
                        // currentCoinContracts = checkDataObject(data, 'currentCoinContracts'),
                        toggleShowContractAddress = () => setData({showContractAddress: !showContractAddress})
                    return <div
                        className={`overflowHidden ${isPhoneClientWidth ? 'paddingPhone childrenSpacingTopPhone' : 'paddingBig childrenSpacingTopBig'}`}
                        style={{
                            border: `solid ${isPhoneClientWidth ? .06 : .03}rem rgba(255,255,255,.4)`,
                            borderRadius: `${isPhoneClientWidth ? 1.65 : .55}rem`,
                        }}>
                        <div onClick={toggleShowContractAddress}
                             className={'flexCenter justifyContentSpaceBetween cursorPointer'}>
                            <div className={`saf activeColor ${isPhoneClientWidth ? '' : 'bigFontSize'}`}>
                                Contract Address
                            </div>
                            <div style={square(`${isPhoneClientWidth ? 1.2 : .4}rem`)}
                                 className={'borderRadius999 backgroundActiveColor flexCenter'}>
                                <img
                                    src={requireDefault(showContractAddress ? 'toBottom' : 'toRight')}
                                    alt=""
                                    style={{width: '70%'}}/>
                            </div>
                        </div>
                        {showContractAddress &&
                            <div className={'overflowHidden'}
                                 style={{border: `solid ${isPhoneClientWidth ? .06 : .03}rem #343434`}}>
                                {
                                    memeContracts.map((v, i) => {
                                        const contractAddress = checkDataString(currentCoinContracts, v[0])
                                        return <div
                                            className={'flex alignItemsCenter colorWhite'}
                                            style={{
                                                height: `${isPhoneClientWidth ? 1.5 : .5}rem`,
                                                ...ternaryArray(i !== 0, {borderTop: `solid ${isPhoneClientWidth ? .09 : .03}rem #343434`})
                                            }}
                                        >
                                            <div
                                                className={'height100 flexCenter'}
                                                style={{
                                                    width: `${isPhoneClientWidth ? 6 : 2}rem`,
                                                    fontSize: `${isPhoneClientWidth ? .45 : .15}rem`,
                                                    borderRight: `solid ${isPhoneClientWidth ? .09 : .03}rem #343434`
                                                }}
                                            >
                                                {v[1]}
                                            </div>
                                            <div style={ternaryObject(isPhoneClientWidth, {padding: '0 .5rem'})}
                                                 className={'paddingLeftRight height100 flex alignItemsCenter justifyContentSpaceBetween flexGrow1'}>
                                                <div
                                                    style={{fontSize: `${isPhoneClientWidth ? .55 : .21}rem`}}
                                                    className={'colorWhite'}>
                                                    {contractAddress}
                                                </div>
                                                <img
                                                    className={'cursorPointer'}
                                                    onClick={() => copyText(contractAddress)}
                                                    src={requireDefault('copy')}
                                                    alt=""
                                                    style={{height: '70%'}}/>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        }
                    </div>
                })(),
                tokenomicsShow = v => {
                    const [text, ...textList] = v[1].split(', ')
                    return (isTokenomicsAuto(currentCoinTokenomics + 1) ? [
                        [v[0], text],
                        ...textList.map(v => {
                            const [percentage, text] = v.split('% ')
                            return [`${percentage}%`, text]
                        })
                    ] : [v])
                },
                buyTokenFuncName = currentCoinLongSymbol ? 'buy' : 'swapExactETHForTokens',
                sellTokenFuncName = currentCoinLongSymbol ? 'sell' : 'swapExactTokensForETH',
                buySellFunctionName = isLaunchSwapBuy ? buyTokenFuncName : sellTokenFuncName,
                maxSlippage = checkData([data, 'maxSlippage'], maxSlippageMax),
                maxSlippageNumber = Number(maxSlippage),
                amountOutMinimum = async (amount = newAmount) => {
                    let aOM = 0, aOM1 = 0
                    if (isV8) {
                        aOM = await getAmountOutFunc(Number(amount) > 0 ? amount : 0, isLaunchSwapBuy, true)
                        if (isMaxSlippage) {
                            aOM1 = Math.floor(npTimes(
                                aOM,
                                npDivide(npMinus(100 - maxSlippageNumber), 100)
                            ))
                        }
                    }
                    // console.log(ternaryArray(isV8, [[aOM], [aOM1]]), '++++++++++++');
                    return ternaryArray(isV8, [[aOM], [aOM1]])
                },
                setBuyMaxETH = () => getDeadline().then(async deadline => {
                        if (Number(ethBalance) > 0) {
                            const aOM = await amountOutMinimum(sendETHBalance)
                            return setMaxETH(
                                buyTokenFuncName,
                                'buy',
                                () => [
                                    address,
                                    ...ternaryArray(
                                        !isV7,
                                        [deadline],
                                    ),
                                    ...checkDataArray(aOM, 1),
                                    ...ternaryArray(isNewMemeNoUTKOrPUPPET, [addressZero])
                                ],
                                buyMaxNoGas,
                                aOM,
                            )
                        } else {
                            return setNewAmount(0)
                        }
                    }
                ),
                depositTotal = checkData0(data, 'depositTotal'),
                toggleShowSwapConfirm = (showSwapConfirm, successFunc = () => {
                }, errorFunc = () => {
                }) => {
                    if (slaveContractAddress) {
                        if (showSwapConfirm) {
                            if (newAmountNumber > 0) {
                                // if (newAmountNumber < Number(isLaunchSwapBuy ? ethBalance : tokenBalance)) {
                                const maxETHAmountEstimateGas = checkData([data, 'maxETHAmountEstimateGas']);
                                // (isNotNull(maxAmount) && newAmountNumber > maxAmount)
                                //     ? toast(`A single transaction cannot be larger than ${npTimes(maxSwapRatio, 100)}% of the pool`)
                                //     : (
                                ((isLaunchSwapBuy && !(isNotNull(maxETHAmountEstimateGas) && (maxETHAmountEstimateGas < maxETHBalance)) && newAmountNumber > maxETHBalance)
                                    || (!isLaunchSwapBuy && Number(checkData0(data, 'priceAmount')) > maxETHBalance))
                                    ? buyCheckSignTrue ? toast(`${depositTotalPrompt}${depositTotal} ETH`) : maxETHBalanceToast()
                                    : axios(`${
                                        sessionDev
                                            ? 'https://testnet-'
                                            : 'https://'
                                    }openapi.vizing.com/sdk/transaction/pending/count`).then(
                                        v => checkData0(v, 'data', 'result', 'count')).catch(() => undefined).then(
                                        messagesIncludeCount => {
                                            setData({messagesIncludeCount})
                                            successFunc(showSwapConfirm)
                                        }
                                    )
                                // )
                                // } else {
                                //     toast('Insufficient account balance')
                                // }
                            } else {
                                toastEmpty()
                            }
                        } else {
                            errorFunc(showSwapConfirm)
                        }
                    } else {
                        toggleShowDeployment(true)
                    }
                },
                buySellConfirm = async (toggleShow = () => {
                }) => {
                    if (buyCheckSignTrue) {
                        toastVerifyHuman()
                    } else {
                        toggleShowLoading(true)
                        const aOM = await amountOutMinimum()
                        getEstimateGas(aOM, true, isLaunchSwapBuy ? 'buy' : 'sell', ...[parseEtherWeiNewAmount]).then(([pongFee, estimateGas]) =>
                            getDeadline().then(deadline => slaveWriteContract(
                                buySellFunctionName,
                                !isLaunchSwapBuy && isMasterContract ? undefined : BigInt(npPlus(isLaunchSwapBuy ? numberParseEtherWei(newAmount) : 0, estimateGas)),
                                [
                                    pongFee,
                                    ...ternaryArray(!isLaunchSwapBuy, [parseEtherWeiNewAmount]),
                                    address,
                                    ...ternaryArray(
                                        !isV7,
                                        [deadline],
                                    ),
                                    ...checkDataArray(aOM, 1),
                                    ...ternaryArray(buySignTrue, [signTrueString])
                                ],
                                hash => {
                                    let count = 0
                                    const interval = (isLaunchSwapBuy ? setInterval : setTimeout)(() => (isLaunchSwapBuy ? getTokenBalance : getBalance)().then(balance => {
                                            count++;
                                            const isSuccess = balance !== (isLaunchSwapBuy ? tokenBalance : ethBalance)
                                            if (!isLaunchSwapBuy || isSuccess || count >= crossChainTimeSeconds) {
                                                if (isSwap && swapPageIsFirst) {
                                                    getSwapEvent()
                                                }
                                                (isLaunchSwapBuy ? getBalance : getTokenBalance)().then(otherBalance => {
                                                    setData({
                                                        newAmount: '',
                                                        ...ternaryObject(!swapPageIsFirst, {swapPage: firstSwapPage}),
                                                        [isLaunchSwapBuy ? 'tokenBalance' : 'ethBalance']: balance,
                                                        [isLaunchSwapBuy ? 'ethBalance' : 'tokenBalance']: otherBalance
                                                    });
                                                })
                                                isSwap && setChart()
                                                clearInterval(interval)
                                                toggleShow()
                                                toggleShowLoading();
                                                (!currentCoinLongSymbol || !isV8) && (isLaunch ? masterContractCall : rC)('launched').then(launched =>
                                                    setData({launched}));
                                                setOmniChain()
                                                setOmniDeposited()
                                                toastSuccessfulHash(hash);
                                            }
                                        }
                                    ), 1000 * (isLaunchSwapBuy ? 1 : crossChainTimeSeconds))
                                }
                            )).catch(() => toggleShowLoading())).catch(() => toggleShowLoading())
                    }
                },
                priceAmount = checkData0(data, 'priceAmount'),
                swiperRef = useRef(null),
                contestCapRef = useRef(null),
                contestHoldersRef = useRef(null),
                capHolder = (() => {
                    const contestCapCoinsKey = 'contestCapCoins', contestHoldersCoinsKey = 'contestHoldersCoins',
                        contestCapCoins = checkDataArray(data, contestCapCoinsKey),
                        contestHoldersCoins = checkDataArray(data, contestHoldersCoinsKey)
                    return [
                        ['Market Cap', 'highest',
                            contestCapCoins.filter(v => !checkDataString(v, 'symbol').includes('LIKTEST')).map((v, i) => [i + 1, [v.logo, v.symbol], v.cmCap, v.maxCmCap]), 'trophy', '#FFEE56',
                            contestCapRef,
                            'maxCmCapSymbol',
                            'maxCmCapAmount',
                            'maxCmCapHolder',
                            'maxCmCapCreator',
                            'maxCmCapLogo',
                            contestCapCoinsPage, coinsPage => contestCapCoinsPage = coinsPage + 1, '4_desc', contestCapCoins, contestCapCoinsKey, 'contestCapCoinsLength'],
                        ['Holders', 'most',
                            contestHoldersCoins.filter(v => !checkDataString(v, 'symbol').includes('LIKTEST')).map((v, i) => [i + 1, [v.logo, v.symbol], v.holders, v.maxHolders]), 'trophy1', '#56F5FF',
                            contestHoldersRef,
                            'maxHolderSymbol',
                            'maxHolderAmount',
                            'maxHolderHolder',
                            'maxHolderCreator',
                            'maxHolderLogo',
                            contestHoldersCoinsPage, coinsPage => contestHoldersCoinsPage = coinsPage + 1, '5_desc', contestHoldersCoins, contestHoldersCoinsKey, 'contestHoldersCoinsLength']
                    ]
                })(),
                getCreateContestSession = (pI = phaseId) => {
                    toggleShowLoading(true)
                    const pIIsNotNull = isNotNull(pI)
                    return requestApi('contest/CreateContestSession1').then(v => {
                        const {length} = phases, phases1 = [...phases],
                            // todo 活动
                            vStatus = v.filter(v => v.id === 1)//v.filter(v => v.status > 0)
                        let obj = {...phases[0]}
                        return Promise.all(v.map((v1, i) => {
                            phases1[i] = v1
                            // todo 活动
                            if (pIIsNotNull ? (v1.id === pI) : (false//(vStatus.length > 0 && v.length === length)
                                ? (v1.status > 0) : (i === 0))) {
                                obj = {...v1}
                            }
                        })).then(() => {
                            const phaseId1 = pIIsNotNull ? pI : obj.id
                            listedPhaseId = phaseId1
                            setData({
                                phases: phases1,
                                phaseId: phaseId1,
                                maxCmCapSymbol: obj.maxCmCapSymbol,
                                maxHolderSymbol: obj.maxHolderSymbol,
                                maxCmCapLogo: memeLogoFunc(obj.maxCmCapLogo),
                                maxHolderLogo: memeLogoFunc(obj.maxHolderLogo),
                            })
                            setCountdown(Math.floor(npDivide(obj.endTime, 1000)))
                            return Promise.all(capHolder.map(async v => {
                                    setData({[v[15]]: undefined})
                                    return await getListedMemeList(undefined, undefined, firstCoinsPage, ...v.slice(12))
                                }
                            )).then(() => toggleShowLoading())
                        })
                    })
                },
                paths = {
                    '/': <div>
                        {isPhoneClientWidth
                            ? pageTopFootPhone(
                                <div>
                                    <div style={{
                                        paddingTop: '9rem',
                                        height: '59.92rem',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center center',
                                        backgroundImage: `url(${requireDefault('homeBGPhone1')})`
                                    }} className={'boxSizingBorderBox'}>
                                        <div className={'flexColumnCenter'}>
                                            <div className={'saf1'} style={{fontSize: '1.8rem'}}>Leverage your</div>
                                            <br/>
                                            <div>
                                                <span className={'saf1'} style={{fontSize: '1.8rem'}}>profits for</span>
                                                <span className={'saf1'}
                                                      style={{fontSize: '1.8rem', marginLeft: '10rem'}}>any token</span>
                                            </div>
                                            <img className={'positionAbsolute marginTop0'}
                                                 style={{width: '16rem', top: '10rem'}}
                                                 src={requireDefault('meme3-3')}
                                                 alt=""/>
                                            <pre className={'textAlignCenter'}
                                                 style={{fontSize: '.8rem', marginTop: '11rem'}}>{`The first fully permissionless, oracle-less, margin trading 
protocol based on uniswap V4.`}</pre>
                                            <div
                                                onClick={() => windowOpen('https://likwid-fi.gitbook.io/likwid-protocol-whitepaper')}
                                                className={'borderRadius999 saf1 flexCenter cursorPointer backgroundActiveColor colorWhite'}
                                                style={{
                                                    marginTop: '1.5rem',
                                                    padding: '0 2.5rem',
                                                    height: '3rem',
                                                }}>
                                                Whitepaper
                                            </div>
                                        </div>
                                        <div style={{marginTop: '5rem', padding: '0 1.5rem'}}
                                             className={'flex justifyContentSpaceBetween flexWrap'}>
                                            {Oracles.map((v, i) => <div
                                                className={'paddingBigPhone boxSizingBorderBox positionRelative zIndex1'}
                                                style={{
                                                    width: '13rem',
                                                    height: '10.6rem',
                                                    marginTop: '.5rem',
                                                    borderRadius: '1.5rem',
                                                    background: 'linear-gradient(180deg, rgba(255,185,194,.2) 0%, rgba(255,255,255,.2) 100%)'
                                                }}>
                                                <div className={'activeColor saf1 smallerFontSizePhone'}
                                                     style={{opacity: .5}}>0{i + 1}</div>
                                                <div className={'childrenSpacingTopPhone'}
                                                     style={{marginTop: '1.2rem'}}>
                                                    <pre className={'saf1'} style={{fontSize: '1rem'}}>{v[1]}</pre>
                                                    <pre style={{fontSize: '.67rem'}}>{v[2]}</pre>
                                                </div>
                                                <img className={'positionAbsolute zIndex-1'}
                                                     style={{width: '45%', top: '.1rem', right: '.1rem'}}
                                                     src={requireDefault(v[0])} alt=""/>
                                            </div>)}
                                        </div>
                                    </div>
                                    <div style={{padding: '0 1.5rem'}}>
                                        <div style={{marginTop: '6rem'}} className={'childrenSpacingTopBiggerPhone'}>
                                                <pre className={'saf1 textAlignCenter'} style={{fontSize: '1.99rem'}}>{`Likwid 
constant product formula`}</pre>
                                            <div className={'childrenSpacingTopBigPhone'}>
                                                {
                                                    likwidFormula.map((v, i) => {
                                                        const is1 = i === 1
                                                        return <div
                                                            className={`paddingPhone ${is1 ? '' : 'borderSmallGrayLine'} childrenSpacingTopPhone`}
                                                            style={{
                                                                borderColor: '#EFEFEF', borderRadius: '.7rem',
                                                                ...is1 ? {
                                                                    background: 'linear-gradient(180deg, rgba(255,185,194,.2) 0%, rgba(255,255,255,.2) 100%)'
                                                                } : {}
                                                            }}>
                                                            <div
                                                                className={'flexCenter justifyContentSpaceBetween'}>
                                                                <div className={'saf1'}
                                                                     style={{fontSize: '1.1rem'}}>{v[0]}</div>
                                                                <div className={is1 ? 'saf1' : ''}
                                                                     style={{fontSize: '1.1rem'}}>{v[1]}</div>
                                                            </div>
                                                            <div style={{height: '1.4rem', borderRadius: '.15rem'}}
                                                                 className={'flex overflowHidden'}>
                                                                <div
                                                                    className={'height100 smallerFontSizePhone flexCenter colorWhite'}
                                                                    style={{
                                                                        width: '34.5%',
                                                                        background: '#627EEA'
                                                                    }}>x=20
                                                                </div>
                                                                <div
                                                                    className={'height100 smallerFontSizePhone flexCenter flexGrow1'}
                                                                    style={{background: '#E8E8E8'}}>y={v[2]}
                                                                </div>
                                                                {v[3] && <div
                                                                    className={'height100 smallerFontSizePhone flexCenter colorWhite backgroundActiveColor'}
                                                                    style={{width: '15%'}}>{v[3]}
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                            <div className={'childrenSpacingTopBigPhone'}>
                                                <div className={'flexCenter'}>
                                                    <img src={requireDefault('formula1')} alt=""
                                                         style={{width: '15rem'}}/>
                                                </div>
                                                <pre style={{fontSize: '.85rem'}}>{formula1}</pre>
                                            </div>
                                        </div>
                                        <div style={{marginTop: '6rem'}}
                                             className={'childrenSpacingTopBiggerPhone'}>
                                                <pre className={'saf1 textAlignCenter'} style={{fontSize: '1.99rem'}}>{`Likwid 
hook on uniswap v4`}</pre>
                                            <div className={'childrenSpacingTop'}>
                                                {
                                                    lkwidHook.map(v => {
                                                        const [title] = v
                                                        return <div
                                                            className={'paddingBigPhone boxSizingBorderBox flex alignItemsCenter childrenSpacingLeftBigPhone'}
                                                            style={{
                                                                height: '6.4rem',
                                                                borderRadius: '1.5rem',
                                                                background: 'linear-gradient(180deg, rgba(255,185,194,.2) 0%, rgba(255,255,255,.2) 100%)'
                                                            }}
                                                        >
                                                            <img src={requireDefault(title)} alt=""
                                                                 style={{width: '4rem'}}/>
                                                            <img src={requireDefault('Line 34')} alt=""
                                                                 className={'height100'}/>
                                                            <div className={'childrenSpacingTopBig'}>
                                                                <div className={'saf1'}
                                                                     style={{fontSize: '1.2rem'}}>{title}</div>
                                                                <div className={'smallFontSizePhone'}>{v[1]}</div>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                            <div className={'flexCenter'}>
                                                <img src={requireDefault('Likwid hook on uniswap v4')} alt=""
                                                     style={{width: '80%'}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        height: '52.68rem',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        padding: '6rem 2.5rem 0 2.5rem',
                                        backgroundPosition: 'center center',
                                        backgroundImage: `url(${requireDefault('RoadmapPhone')})`
                                    }} className={'boxSizingBorderBox'}>
                                        <div className={'saf1 textAlignCenter'} style={{fontSize: '1.99rem'}}>
                                            Roadmap
                                        </div>
                                        <div style={{marginTop: '5rem'}}>
                                            {Roadmaps.map((v, i) => <div className={'flex justifyContentSpaceBetween'}
                                                                         style={{
                                                                             marginTop: i === 0 ? 0 : '3.1rem',
                                                                             ...i % 2 === 0 ? {} : {flexDirection: 'row-reverse'},
                                                                             ...i > 1 ? {transform: `translate(${i === Roadmaps.length - 1 ? '-1rem' : '0'},-1.1rem)`} : {}
                                                                         }}
                                            >
                                                <div className={'childrenSpacingTopPhone'}>
                                                    <div className={'saf1'} style={{fontSize: '1.4rem'}}>
                                                        {v[0]}
                                                    </div>
                                                    <pre style={{fontSize: '.95rem'}}>{v[1]}</pre>
                                                </div>
                                                <div/>
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                            )
                            : <div>
                                {pageTop}
                                <div style={{
                                    height: '20.58rem',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                    backgroundImage: `url(${requireDefault('homeBG1')})`
                                }}>
                                    <div className={'flexColumnCenter positionRelative'} style={{paddingTop: '2rem'}}>
                                        <div className={'saf1 fontSizePhone'}>Leverage your</div>
                                        <div>
                                            <span className={'saf1 fontSizePhone'}>profits for</span>
                                            <span className={'saf1 fontSizePhone'}
                                                  style={{marginLeft: '4.5rem'}}>any token</span>
                                        </div>
                                        <img className={'positionAbsolute marginTop0'} style={{width: '7rem', top: '3rem'}}
                                             src={requireDefault('meme3-3')}
                                             alt=""/>
                                        <pre className={'textAlignCenter bigFontSize'} style={{marginTop: '5rem'}}>{`The first fully permissionless, oracle-less, margin trading protocol
based on uniswap V4.`}</pre>
                                        <div
                                            onClick={() => windowOpen('https://likwid-fi.gitbook.io/likwid-protocol-whitepaper')}
                                            className={'borderRadius999 saf1 flexCenter cursorPointer backgroundActiveColor colorWhite marginTopPhone bigFontSize'}
                                            style={{
                                                padding: '0 .5rem',
                                                height: '1rem',
                                            }}>
                                            Whitepaper
                                        </div>
                                        <div style={{marginTop: '3rem'}} className={'flexCenter childrenSpacingLeftBig'}>
                                            {Oracles.map((v, i) => <div
                                                className={'borderRadiusPhone paddingBigger boxSizingBorderBox positionRelative zIndex1'}
                                                style={{
                                                    width: '6rem',
                                                    height: '4.55rem',
                                                    background: 'linear-gradient(180deg, rgba(255,185,194,.2) 0%, rgba(255,255,255,.2) 100%)'
                                                }}>
                                                <div className={'activeColor saf1'} style={{opacity: .5}}>0{i + 1}</div>
                                                <div className={'childrenSpacingTopBig'} style={{marginTop: '.9rem'}}>
                                                    <pre className={'saf1'} style={{fontSize: '.5rem'}}>{v[1]}</pre>
                                                    <pre className={'smallFontSize'}>{v[2]}</pre>
                                                </div>
                                                <img className={'positionAbsolute zIndex-1'}
                                                     style={{width: '45%', top: '.1rem', right: '.1rem'}}
                                                     src={requireDefault(v[0])} alt=""/>
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    paddingTop: '2.5rem',
                                }} className={'pagePaddingLeftRight childrenSpacingTopPhone'}>
                                    <div className={'saf1'} style={{fontSize: '.83rem'}}>Likwid constant product formula
                                    </div>
                                    <div className={'flex alignItemsFlexEnd justifyContentSpaceBetween'}>
                                        <div className={'childrenSpacingTopBigger'} style={{width: '14.2rem'}}>
                                            {
                                                likwidFormula.map((v, i) => {
                                                    const is1 = i === 1
                                                    return <div
                                                        className={`paddingBigger ${is1 ? '' : 'borderSmallGrayLine'} childrenSpacingTopBig`}
                                                        style={{
                                                            borderColor: '#EFEFEF', borderRadius: '.3rem',
                                                            ...is1 ? {
                                                                background: 'linear-gradient(180deg, rgba(255,185,194,.2) 0%, rgba(255,255,255,.2) 100%)'
                                                            } : {}
                                                        }}>
                                                        <div className={'flexCenter justifyContentSpaceBetween'}>
                                                            <div className={'saf1'}>{v[0]}</div>
                                                            <div className={is1 ? 'saf1' : ''}>{v[1]}</div>
                                                        </div>
                                                        <div style={{height: '.35rem', borderRadius: '.05rem'}}
                                                             className={'flex overflowHidden'}>
                                                            <div className={'height100 smallFontSize flexCenter colorWhite'}
                                                                 style={{width: '34.5%', background: '#627EEA'}}>x=20
                                                            </div>
                                                            <div className={'height100 smallFontSize flexCenter flexGrow1'}
                                                                 style={{background: '#E8E8E8'}}>y={v[2]}
                                                            </div>
                                                            {v[3] && <div
                                                                className={'height100 smallFontSize flexCenter colorWhite backgroundActiveColor'}
                                                                style={{width: '15%'}}>{v[3]}
                                                            </div>}
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div className={'childrenSpacingTopBigger flexColumnCenter'}>
                                            <img src={requireDefault('formula1')} alt="" style={{width: '7rem'}}/>
                                            <pre>{`In long trading operations, users acquire Y using X.
The protocol’s pricing mechanism follows the formula x(y+y′)=k,

where:
x: Represents the amount of X.
y: Represents the target ERC-20 token quantity.
y′: A mapped derivative token created by the protocol.`}</pre>
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    paddingTop: '2.5rem',
                                    paddingBottom: '2.5rem',
                                }} className={'pagePaddingLeftRight childrenSpacingTopBigger'}>
                                    <div className={'saf1'} style={{fontSize: '.83rem'}}> Likwid hook on uniswap v4</div>
                                    <div className={'flex justifyContentSpaceBetween alignItemsFlexEnd'}>
                                        <div className={'childrenSpacingTop'}>
                                            {
                                                lkwidHook.map(v => {
                                                    const [title] = v
                                                    return <div
                                                        className={'borderRadiusPhone paddingBigger boxSizingBorderBox flex alignItemsCenter childrenSpacingLeftBigger'}
                                                        style={{
                                                            width: '11.3rem',
                                                            height: '2.6rem',
                                                            background: 'linear-gradient(180deg, rgba(255,185,194,.2) 0%, rgba(255,255,255,.2) 100%)'
                                                        }}
                                                    >
                                                        <img src={requireDefault(title)} alt="" style={{width: '1.5rem'}}/>
                                                        <img src={requireDefault('Line 34')} alt=""
                                                             className={'height100'}/>
                                                        <div className={'childrenSpacingTop'}>
                                                            <div className={'saf1'}
                                                                 style={{fontSize: '.47rem'}}>{title}</div>
                                                            <div className={'smallFontSize'}>{v[1]}</div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <img src={requireDefault('Likwid hook on uniswap v4')} alt=""
                                             style={{width: '10rem'}}/>
                                    </div>
                                </div>
                                <div style={{
                                    height: '10.4rem',
                                    marginBottom: '2rem',
                                    paddingTop: '1rem',
                                    paddingBottom: '1rem',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                    backgroundImage: `url(${requireDefault('Roadmap')})`
                                }} className={'boxSizingBorderBox pagePaddingLeftRight'}>
                                    <div className={'saf1'} style={{fontSize: '.83rem'}}>Roadmap</div>
                                    <div style={{padding: '0 0 0 2.3rem', marginTop: '4.7rem'}}
                                         className={'flex childrenFlexGrow1'}>
                                        {
                                            Roadmaps.map(v => <div className={'childrenSpacingTopSmall'}>
                                                <div className={'saf1'} style={{fontSize: '.5rem'}}>{v[0]}</div>
                                                <pre className={'smallFontSize'}>{v[1]}</pre>
                                            </div>)
                                        }
                                    </div>
                                </div>
                                {pageFoot}
                            </div>}
                        {loading}
                    </div>
                },
                pathNames = Object.keys(paths),
                [firstPath] = pathNames,
                chainChange = [slaveContractAddress, currentChainId],
                useRefEffect = (fn = () => {
                }, inputs = []) => {
                    const didMountRef = useRef(false)
                    useEffect(() => {
                        fn(didMountRef.current)
                        if (!didMountRef.current) didMountRef.current = true
                    }, inputs);
                },
                getListedMemeListCondition = isHome || isLaunch || isSwap || isPortfolio || (isVotePage && !isVoteDetail);
            [
                [
                    () => {
                        setData({init: true})
                        const getTop = () => setData({
                            isTop: (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) === 0
                        })
                        getTop()
                        window.addEventListener('scroll', getTop)
                    },
                ]
            ].map(v => useEffect(
                checkDataFunction(v, 0),
                checkDataArray(v, 1)
            ));
            [].map(v => useRefEffect(...v));

            function replaceHome() {
                windowReplace(firstPath)
            }

            function rC(functionName, ...args) {
                return readContract(
                    {
                        args,
                        functionName,
                        abi: slaveContractAbi,
                        address: slaveContractAddress,
                    },
                )
            }

            useAccount({
                onConnect: () => {
                },
                onDisconnect: () => {
                },
            })
            useChainId()
            return [
                contextHolder,
                <Routes onChang={(() => document.title = `LIKWID${pathname ? `-${pNs1 ? pathname : pNs0}` : ''}`)()}>
                    <Route path='*' element={<Navigate to={firstPath}/>}/>
                    {pathNames.map(v => <Route key={v} path={v} element={paths[v]}/>)}
                </Routes>
            ]
        }
    )
createWeb3Modal({
    wagmiConfig, projectId, chains,
    themeVariables: {'--w3m-accent': '#FFB9C2'},
    featuredWalletIds: [
        'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
        '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
        '0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150',
        '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709'
    ],
    chainImages: (() => {
        const walletConnectChainIcon = chain => requireDefault(`walletConnectChainIcons/${chain}`)
        return {
            1: walletConnectChainIcon('ETH'),
            56: walletConnectChainIcon('BNB'),
            97: walletConnectChainIcon('BNB'),
            59144: walletConnectChainIcon('Linea'),
            8453: walletConnectChainIcon('Base'),
            534352: walletConnectChainIcon('Scroll'),
            42161: walletConnectChainIcon('Arbitrum'),
            10: walletConnectChainIcon('Optimism'),
            1101: walletConnectChainIcon('Polygon zkEVM'),
            81457: walletConnectChainIcon('Blast'),
            60808: walletConnectChainIcon('BOB'),
            421614: walletConnectChainIcon('Arbitrum'),
            2442: walletConnectChainIcon('Polygon zkEVM'),
            111: walletConnectChainIcon('BOB'),
            167000: walletConnectChainIcon('Taiko'),
            28516: walletConnectChainIcon('Vizing'),
            28518: walletConnectChainIcon('Vizing'),
        }
    })()
})
export default () => [
    <WagmiConfig config={wagmiConfig}><Profile/></WagmiConfig>,
    // <Web3Modal projectId={projectId} defaultChain={defaultChain}
    //            chainImages={(() => {
    //                const walletConnectChainIcon = chain => requireDefault(`walletConnectChainIcons/${chain}`)
    //                return {
    //                    59144: walletConnectChainIcon('Linea'),
    //                    8453: walletConnectChainIcon('Base'),
    //                    534352: walletConnectChainIcon('Scroll'),
    //                    42161: walletConnectChainIcon('Arbitrum'),
    //                    10: walletConnectChainIcon('Optimism'),
    //                    1101: walletConnectChainIcon('Polygon zkEVM'),
    //                    81457: walletConnectChainIcon('Blast'),
    //                    60808: walletConnectChainIcon('BOB'),
    //                    421614: walletConnectChainIcon('Arbitrum'),
    //                    2442: walletConnectChainIcon('Polygon zkEVM'),
    //                    111: walletConnectChainIcon('BOB'),
    //                    28516: walletConnectChainIcon('Vizing'),
    //                    28518: walletConnectChainIcon('Vizing'),
    //                }
    //            })()}
    //            ethereumClient={ethereumClient} themeMode={'dark'} themeVariables={{
    //     '--w3m-accent-color': '#FFB9C2',
    //     '--w3m-background-color': '#FFB9C2',
    //     '--w3m-accent-fill-color': 'black',
    // }}/>
]